import Style from './Input.module.less';
import classNames from 'classnames';
import React, { useImperativeHandle, useRef, useState } from 'react';

let Input = (props, ref) => {
    let { HeadIcon, FooterIcon, placeholder, stripe, className } = props;
    let Ref = useRef(null);
    const [errTip, setErrTip] = useState(null);
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return Ref.current.value;
            },
            cleanErr: () => {
                setErrTip(null);
            },
            setErr: text => {
                setErrTip(text);
            },
        };
    });
    return (
        <div className={classNames(Style.input_box, className)}>
            <div className={Style.content}>
                {HeadIcon ? <div className={Style.head_icon}>{HeadIcon}</div> : null}
                <input ref={Ref} placeholder={placeholder} data-stripe={stripe} />
                {FooterIcon}
            </div>
            {errTip ? <div className={classNames(Style.err)}>{errTip}</div> : <div className={classNames(Style.err)} />}
        </div>
    );
};
export default React.forwardRef(Input);
