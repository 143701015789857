const FJPayUtil = {
    /**
     * @description 返回cookie
     * @param name
     * @returns {null|string}
     */
    getCookie: function (name) {
        let arr;
        let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if (arr === document.cookie.match(reg)) return unescape(arr[2]);
        else return null;
    },

    /**
     * @description 刷新账单列表
     */
    refreshInvoices: function () {
        if (window.fj.billing && window.fj.billing.chargeHistory) {
            window.FJGlobalariable.modSubscription.network
                .updateInvoices()
                .then(res => {
                    if (res.data.length === window.fj.billing.chargeHistory.length) {
                        setTimeout(() => {
                            this.refreshInvoices();
                        }, 3000);
                    } else {
                        window.fj.billing.chargeHistory = res.data;
                    }
                })
                .catch(error => {})
                .finally(() => {});
        }
    },
};

export default FJPayUtil;
