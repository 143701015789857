import React, { Component } from 'react';

import Style from './FJCssSwiper.module.less';
import YouTubeLogo from '../../../../icon/platform/youtube.svg';
import WindowLogo from '../../../../icon/platform/windows.svg';
import GoogleLogo from '../../../../icon/platform/google.svg';
import AmazonLogo from '../../../../icon/platform/amazon.svg';
import DropboxLogo from '../../../../icon/platform/dropbox.svg';
import MetaLogo from '../../../../icon/platform/meta.svg';
import VisaLogo from '../../../../icon/platform/visa.svg';
import StoryBlocksLogo from '../../../../icon/platform/storyblocks.svg';
import InlineSVG from 'react-inlinesvg';

import classNames from 'classnames';

export default class FJCssSwiper extends Component {
    constructor(props) {
        super(props);
    }

    TRUSTED_ICON = [
        YouTubeLogo,
        WindowLogo,
        GoogleLogo,
        AmazonLogo,
        DropboxLogo,
        MetaLogo,
        VisaLogo,
        StoryBlocksLogo,
        YouTubeLogo,
        WindowLogo,
        GoogleLogo,
        AmazonLogo,
        DropboxLogo,
        MetaLogo,
        VisaLogo,
        StoryBlocksLogo,
    ];

    __drawIcon() {
        return this.TRUSTED_ICON.map((item, index) => {
            return (
                <div className={Style.trustedIconBox} key={'trusted platform' + index}>
                    <InlineSVG src={item} />
                </div>
            );
        });
    }

    render() {
        const { boxClass = '', logoBoxClass = '' } = this.props;
        return (
            <div className={classNames(Style.layoutBox, boxClass)}>
                <div className={classNames(Style.logo_bbox, logoBoxClass)}>{this.__drawIcon()}</div>
            </div>
        );
    }
}
