import Style from './FJButton.module.less';
import FJLoading from '../FJLoading/FJLoading';
import classNames from 'classnames';

const FJButton = props => {
    let { text, isLoading = false, className, style, small, onClick } = props;
    let curClassName = classNames(Style.btn, { [Style.btn_small]: small, [Style.loading]: isLoading }, className);
    let _click = () => {
        if (isLoading === true) {
            return;
        }
        onClick();
    };
    return (
        <button className={curClassName} style={style} onClick={_click}>
            <FJLoading loading={isLoading} />
            {isLoading ? '' : text}
            {/* <span className={classNames({[Style.text_hidden]: isLoading})}>{text}</span> */}
        </button>
    );
};
export default FJButton;
