import React, { useEffect, useImperativeHandle, useRef } from 'react';
import Style from './CreditCardPanel.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import Input from './Input';
import FJStripePayContainer from '../FJStripePay/container/FJStripePayContainer';
import FJUtil from '../../util/FJUtil';

let CreditCardPanel = (props, ref) => {
    let { style, mode } = props;
    const _stripeRef = useRef(null);
    let nameRef = useRef(null);
    let cardRef = useRef(null);
    let dateRef = useRef(null);
    let cvcRef = useRef(null);
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return {
                    name: nameRef.current.getValue(),
                    number: cardRef.current.getValue(),
                    date: dateRef.current.getValue(),
                    CVC: cvcRef.current.getValue(),
                };
            },
            dataValid: _dataValid,
            confirmPayment: (planData, options) => {
                _stripeRef.current.handleSubmit(planData, options);
            },
        };
    });
    useEffect(() => {
        setTimeout(() => {
            window.jQuery('#card-element [data-stripe="number"]').payment('formatCardNumber');
            window.jQuery('#card-element [data-stripe="exp"]').payment('formatCardExpiry');
            window.jQuery('#card-element [data-stripe="cvc"]').payment('formatCardCVC');
        }, 0);
        // $('#card-change-element [data-stripe="number"]').payment('formatCardNumber');
    }, []);
    let _cleanErrTip = () => {
        nameRef.current.cleanErr();
        cardRef.current.cleanErr();
        dateRef.current.cleanErr();
        cvcRef.current.cleanErr();
    };
    let _dataValid = card => {
        let cardType = window.jQuery.payment.cardType(card.number);
        if (card.name.length < 1) {
            // 用户名为空
            // this.errorTexts.name = text_('SUB.INVALID_NAME');
            nameRef.current.setErr(FJLocalStore._('INVALID_NAME'));
            return false;
        }

        // 清空所有错误信息
        _cleanErrTip();

        if (!window.jQuery.payment.validateCardNumber(card.number)) {
            // 信用卡验证失败
            // this.errorTexts.number = text_('SUB.INVALID_NUMBER');
            cardRef.current.setErr(FJLocalStore._('INVALID_NUMBER'));
            return false;
        }
        if (
            !window.jQuery.payment.validateCardExpiry(
                window.jQuery('#card-element [data-stripe="exp"]').payment('cardExpiryVal'),
            )
        ) {
            // 验证过期时间失败
            // this.errorTexts.date = text_('SUB.INVALID_EXP_DATE');
            dateRef.current.setErr(FJLocalStore._('INVALID_EXP_DATE'));
            return false;
        }
        if (!window.jQuery.payment.validateCardCVC(card.CVC, cardType)) {
            // 验证安全码失败
            // this.errorTexts.cvc = text_('SUB.INVALID_CVC');
            cvcRef.current.setErr(FJLocalStore._('INVALID_CVC'));
            return false;
        }
        return true;
    };

    return (
        <div className={Style.panel_box} style={style}>
            {FJUtil.isNewPay() ? (
                <FJStripePayContainer ref={_stripeRef} mode={mode} />
            ) : (
                <form id='card-element'>
                    <div className={Style.input_title}>{FJLocalStore._('PAY_CARD_NAME')}</div>
                    <Input ref={nameRef} placeholder={FJLocalStore._('PAY_CARD_NAME')} stripe='name' />
                    <div className={Style.input_title}>{FJLocalStore._('PAY_CARD_NUMBER')}</div>
                    <Input ref={cardRef} placeholder={FJLocalStore._('PAY_CARD_NUMBER')} stripe='number' />
                    <div className={Style.time_cvc_box}>
                        <div>
                            <div className={Style.input_title}>{FJLocalStore._('expire-date')}</div>
                            <Input ref={dateRef} placeholder={FJLocalStore._('PAY_CARD_DATE')} stripe='exp' />
                        </div>
                        <div>
                            <div className={Style.input_title}>{FJLocalStore._('PAY_CARD_CVC')}</div>
                            <Input ref={cvcRef} placeholder={FJLocalStore._('PAY_CARD_CVC')} stripe='cvc' />
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};
export default React.forwardRef(CreditCardPanel);
