import Style from './TeamSeatsPanel.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import decreaseIcon from '../../icon/decrease.svg';
import addIcon from '../../icon/add.svg';
import InlineSVG from 'react-inlinesvg';
import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const TeamSeatsPanel = ({ seatCount, onChangeSeats }) => {
    const [isFocus, setIsFocus] = useState(false);
    const minSeatCount = useSelector(state => state.mainReducer.minSeatCount);
    const maxSeatCount = useSelector(state => state.mainReducer.maxSeatCount);
    const teamSubscription = useSelector(state => state.mainReducer.teamSubscription);

    // 允许改变的最小席位数，以带进来的值为第一判断，以团队成员为第二判断，取最大值
    let computedMinSeatCount = useMemo(() => {
        if (teamSubscription && teamSubscription.members) {
            return Math.max(teamSubscription.members, minSeatCount);
        } else {
            return minSeatCount;
        }
    }, [teamSubscription, minSeatCount]);

    const _onChange = e => {
        if (e.target.value === '') {
            onChangeSeats('');
        } else if (e.target.validity.valid) {
            const length = Math.max(Math.min(Number(e.target.value), maxSeatCount), 1);
            onChangeSeats(length);
        }
    };

    const _onBlur = () => {
        setIsFocus(false);

        const length = Number(seatCount);
        if (length < computedMinSeatCount) {
            onChangeSeats(length);
        }
    };

    const _onFocus = () => {
        setIsFocus(true);
    };
    const canAdd = seatCount < maxSeatCount;
    const canDecrease = seatCount > computedMinSeatCount;

    const _onAdd = () => {
        if (!canAdd) return;
        const newLength = Number(seatCount) + 1;
        onChangeSeats(newLength);
    };

    const _onDecrease = () => {
        if (!canDecrease) return;
        const newLength = Number(seatCount) - 1;
        onChangeSeats(newLength);
    };

    return (
        <div className={Style.team_panel}>
            <div className={Style.title}>{FJLocalStore._('How many seats do you need?')}</div>
            <div className={Style.input}>
                <div className={classNames(Style.btn, { [Style.disabled]: !canDecrease })} onClick={_onDecrease}>
                    <InlineSVG src={decreaseIcon} />
                </div>
                <input
                    value={seatCount}
                    pattern='[0-9]*'
                    type='text'
                    onChange={_onChange}
                    onBlur={_onBlur}
                    onFocus={_onFocus}
                />
                <div className={classNames(Style.btn, { [Style.disabled]: !canAdd })} onClick={_onAdd}>
                    <InlineSVG src={addIcon} />
                </div>
            </div>
        </div>
    );
};

export default TeamSeatsPanel;
