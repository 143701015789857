import Style from './PanelButton.module.less';

import classnames from 'classnames';
import FJLocalStore from '../../../../store/FJLocalStore';

import { useSelector, useDispatch } from 'react-redux';
import mainAction from '../../../../reducer/action/mainAction';

export default function PanelButton(props) {
    const { boxStyle = '' } = props;
    let dispatch = useDispatch();

    let panelName = useSelector(state => state.mainReducer.panelName);

    let changeTopPanel = value => {
        dispatch(mainAction.changeTopPanel(value));
    };

    return (
        <div className={classnames(Style.panelButtonBox, boxStyle)}>
            <img src='https://resource.flexclip.com//pages/company/pricing/banner.png' alt='btn background' />
            <button
                className={classnames(Style.buttonItem, { [Style.buttonItemActive]: panelName === 'Subscription' })}
                onClick={() => {
                    changeTopPanel('Subscription');
                }}
            >
                {FJLocalStore._('Subscription')}
            </button>
            <button
                className={classnames(Style.buttonItem, { [Style.buttonItemActive]: panelName === 'Credits' })}
                onClick={() => {
                    changeTopPanel('Credits');
                }}
            >
                {FJLocalStore._('Credits')}
            </button>
        </div>
    );
}
