import React, { useRef, useState, useLayoutEffect } from 'react';
import Style from './HoverDrop.module.less';
import classNames from 'classnames';

let HoverDrop = props => {
    let { children, overlay, placement = 'left' } = props;
    const [isShow, setIsShow] = useState(false);
    let Ref = useRef(null);
    let RefOverly = useRef(null);

    useLayoutEffect(() => {
        _setPosition();
    }, [isShow]);

    let _show = () => {
        setIsShow(true);
    };
    let _hidden = () => {
        setIsShow(false);
    };
    let _setPosition = () => {
        let rect = Ref.current.getBoundingClientRect();
        let overlyRectInfo = RefOverly.current.getBoundingClientRect();

        let top = rect.top - overlyRectInfo.height / 2;

        // 浮窗的高度大于了可视窗口的高度，则浮窗底部位置在 ？ 的top
        if (top + overlyRectInfo.height > window.innerHeight) {
            top = rect.top - overlyRectInfo.height;
        }

        // 如果 ？ 右边的宽度不能够显示浮窗，则判断左边是否能完整显示
        if (window.innerWidth - rect.right < overlyRectInfo.width + 10) {
            // 左边能完整显示
            if (rect.left > overlyRectInfo.width + 10) {
                RefOverly.current.style.top = top + 'px';
                RefOverly.current.style.left = rect.left - overlyRectInfo.width - 10 + 'px';
            } else {
                // 左边也不能完整显示，则判断 ？ 的下、上两个方向能否放下浮窗
                if (rect.bottom + overlyRectInfo.height > window.innerHeight) {
                    RefOverly.current.style.top = rect.top - overlyRectInfo.height - 10 + 'px';
                    RefOverly.current.style.left = (window.innerWidth - overlyRectInfo.width) / 2 + 'px';
                } else {
                    RefOverly.current.style.top = rect.bottom + 10 + 'px';
                    RefOverly.current.style.left = (window.innerWidth - overlyRectInfo.width) / 2 + 'px';
                }
            }
        } else {
            RefOverly.current.style.top = top + 'px';
            RefOverly.current.style.left = rect.right + 10 + 'px';
        }
    };
    let cloneEle = React.cloneElement(children, {
        // active: canTriggerActive ? shown : '',
        // onClick: trigger === 'click' ? () => {
        //     this._onTriggerEleClick();
        //     this.handleClick();
        // } : () => {
        //     this._onTriggerEleClick()
        // },
        ref: Ref,
        onMouseEnter: _show,
        onMouseLeave: _hidden,
        // style: {...children.props.style, display: active ? display : 'none'},
    });

    return (
        <div style={{ display: 'inline-block' }}>
            {cloneEle}
            <div ref={RefOverly} className={classNames(Style.overlay_box, { [Style.show]: isShow })}>
                {overlay}
            </div>
        </div>
    );
};
export default HoverDrop;
