import FJActionConstant from '../../constant/FJActionConstant';
import FJViewConstant from '../../constant/FJViewConstant';
import FJMobilePayAction from './FJMobilePayAction';

export default {
    // 打开视图
    openSubscribeView: obj => {
        window.callbackWhenOpenSubscription && window.callbackWhenOpenSubscription();
        return {
            type: FJActionConstant.SHOW_SUBSCRIBE_VIEW,
            entry: obj.entry,
            method: obj.method,
            planType: obj.planType,
            additionalSeatCount: obj.additionalSeatCount,
            calculatorSeatCount: obj.calculatorSeatCount,
            panelName: obj.panelName,
            showCreditRuleTable: obj.showCreditRuleTable,
            showQuestionnaire: obj.showQuestionnaire,
        };
    },
    // 关闭视图
    closeSubscribeView: function () {
        window.callbackWhenCloseSubscription && window.callbackWhenCloseSubscription();
        return (dispatch, getState) => {
            const { method } = getState().mainReducer;
            dispatch({
                type: FJActionConstant.CLOSE_SUBSCRIBE_VIEW,
            });
            dispatch(this.changeStepAction(1));
            if (method === 'popup') {
                dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.CREDITS_CENTER));
            }
        };
    },
    openEditCardInfoPopup: (params = null) => {
        return {
            type: FJActionConstant.OPEN_CARD_INFO_EDIT_POPUP,
            status: true,
            cardParams: params,
        };
    },
    closeEditCardInfoPopup: () => {
        return {
            type: FJActionConstant.CLOSE_CARD_INFO_EDIT_POPUP,
            status: false,
        };
    },
    changePayMethodAction: method => {
        return {
            type: FJActionConstant.CHANGE_PAY_METHOD,
            method: method,
        };
    },
    changeStepAction: step => {
        return {
            type: FJActionConstant.CHANGE_CURRENT_STEP,
            step: step,
        };
    },
    changeSelectPageAction: page => {
        return {
            type: FJActionConstant.CHANGE_SELECT_PAGE,
            selectPage: page,
        };
    },
    changeLoadingStatusAction: status => {
        return {
            type: FJActionConstant.CHANGE_LOADING_STATUS,
            status: status,
        };
    },
    setCurrentPlan: obj => {
        return {
            type: FJActionConstant.SET_CURRENT_PLAN,
            obj: obj,
        };
    },
    setDataInfo: obj => {
        return {
            type: FJActionConstant.SET_DATA_INFO,
            obj: obj,
        };
    },
    setDiscountConfAction: obj => {
        return {
            type: FJActionConstant.SET_DISCOUNT_CONF,
            obj: obj,
        };
    },
    // 设置当前套餐对应的折扣
    setCurrentDiscountData: obj => {
        return {
            type: FJActionConstant.SET_CURRENT_DISCOUNT_DATA,
            obj: obj,
        };
    },
    setPaypalSdkAction: value => {
        return {
            type: FJActionConstant.SET_PAYPAL_SDK_STATUS,
            value: value,
        };
    },

    setTeamSubscription: teamSubscription => {
        return {
            type: FJActionConstant.SET_TEAM_SUBSCRIPTION,
            teamSubscription,
        };
    },
    updateSeatCount: count => {
        return (dispatch, getState) => {
            const { minSeatCount, maxSeatCount } = getState().mainReducer;
            const seatCount = Math.min(Math.max(count, minSeatCount), maxSeatCount);
            dispatch({
                type: FJActionConstant.UPDATE_SEAT_COUNT,
                count: seatCount,
            });
        };
    },
    updateIsAnnually: value => {
        return {
            type: FJActionConstant.UPDATE_IS_ANNUALLY,
            isAnnually: value,
        };
    },

    changeTopPanel: value => {
        return {
            type: FJActionConstant.CHANGE_TOP_PANEL,
            value,
        };
    },

    // 打开或者关闭积分套餐支付面板
    openOrCloseCreditsPayPanel: value => {
        return {
            type: FJActionConstant.OPEN_OR_CLOSE_CREDITS_PAY_PANEL,
            value,
        };
    },

    // 展示或者隐藏顶部切换按钮
    showOrHiddenPanelButton: value => {
        return {
            type: FJActionConstant.SHOW_OR_HIDDEN_PANEL_BUTTON,
            value,
        };
    },

    // 切换默认选中的积分套餐
    changeCheckedCredits: obj => {
        return {
            type: FJActionConstant.CHANGE_CHECKED_CREDITS,
            obj,
        };
    },

    // 积分购买成功后显示的success页面
    changeBuyCreditsSuccessPage: value => {
        return {
            type: FJActionConstant.CHANGE_BUY_CREDITS_SUCCESS_PAGE,
            value,
        };
    },

    // 变更团队席成员位数
    changeTeamSeatCount: value => {
        return {
            type: FJActionConstant.SET_SEAT_COUNT,
            value,
        };
    },

    // 设置当前用户所在地区
    setCurrentCountry: value => {
        return {
            type: FJActionConstant.SET_CURRENT_COUNTRY,
            value,
        };
    },

    // 设置当前用户所在地区币种
    setCurrentCountryMonetary: value => {
        return {
            type: FJActionConstant.SET_CURRENT_COUNTRY_MONETARY,
            value,
        };
    },

    // 设置当前用户所在地区币种的ISO码
    setCurrentCountryMonetaryISO: value => {
        return {
            type: FJActionConstant.SET_CURRENT_COUNTRY_MONETARY_ISO,
            value,
        };
    },

    // 设置当前用户所在地区币种与美元之间的汇率
    setExchangeRate: value => {
        return {
            type: FJActionConstant.SET_EXCHANGE_RATE,
            value,
        };
    },
};
