import SubscribeStepContainer from '../SubscribeStep/SubscribeStepContainer';
import Style from './PopupPage.module.less';
const PopupPage = () => {
    return (
        <div className={Style.popup_box}>
            <SubscribeStepContainer popupType />
        </div>
    );
};
export default PopupPage;
