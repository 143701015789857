import FJComparePlans from '../view/FJComparePlans';
import useLocalStore from '../../../hooks/useLocalStore';
// 订阅展示表
const FJComparePlansContainer = props => {
    useLocalStore();
    const { active, changePlan, popupType } = props;

    if (!active) {
        return null;
    }

    return <FJComparePlans popupType={popupType} changePlan={changePlan} />;
};
export default FJComparePlansContainer;
