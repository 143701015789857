import styles from './FJMobileInput.module.less';
import InlineSvg from 'react-inlinesvg';
import MobileOkSvg from '../../../icon/mobile-ok.svg';
import MobileCancelSvg from '../../../icon/mobile-cancel.svg';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';

const FJMobileInput = (props, ref) => {
    const { title = '', placeholder = '', type = 'text', onSubmit } = props;
    const [visible, setVisible] = useState(false);
    const inputRef = useRef(null);
    useImperativeHandle(ref, () => {
        return {
            show: bool => {
                if (bool === undefined || bool === null) {
                    setVisible(true);
                } else {
                    setVisible(bool);
                }
            },
        };
    });
    const _onclose = () => {
        setVisible(false);
    };
    const _onSubmit = () => {
        onSubmit && onSubmit(inputRef.current.value);
        setVisible(false);
    };
    const _onKeyup = e => {
        if (e.key === 'Enter') {
            _onSubmit();
        }
    };
    let maskCls = classNames(styles.mobile_input_mask, visible ? styles.modal_fade_in : styles.modal_fade_out);
    let containerCls = classNames(
        styles.mobile_input_modal,
        visible ? styles.modal_zoom_modal_in : styles.modal_zoom_modal_out,
    );
    useEffect(() => {
        if (visible && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.value = '';
        }
    }, [visible]);
    return (
        <div
            className={maskCls}
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setVisible(false);
            }}
        >
            <div
                className={containerCls}
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <div className={styles.mobile_input_header}>
                    <InlineSvg src={MobileCancelSvg} onClick={_onclose} />
                    <div className={styles.mobile_input_title}>{title}</div>
                    <InlineSvg src={MobileOkSvg} onClick={_onSubmit} />
                </div>
                <input
                    className={styles.mobile_input}
                    ref={inputRef}
                    type={type}
                    placeholder={placeholder}
                    onKeyUp={_onKeyup}
                />
            </div>
        </div>
    );
};
export default forwardRef(FJMobileInput);
