import Style from './FJLoading.module.less';
import classNames from 'classnames';

const FJLoading = props => {
    let { loading } = props;
    return (
        <div className={classNames(Style.icon_box, { [Style.icon_box_show]: loading })}>
            <div />
            <div />
            <div />
        </div>
    );
};
export default FJLoading;
