import Style from './FJComparePlans.module.less';
import FJLocalStore from '../../../store/FJLocalStore';
import classNames from 'classnames';
import { PersonPlanList, AI_LIMIT, TeamPlanList } from '../../../config/FJPlanData';
import FJViewConstant from '../../../constant/FJViewConstant';
import CheckIcon from '../../../icon/FJCheck.svg';
import InlineSVG from 'react-inlinesvg';
import FJUtil from '../../../util/FJUtil';
import { useEffect, useMemo, useState } from 'react';
import HoverDrop from '../../common/HoverDrop';
import questionIcon from '../../../icon/question.svg';
import ChangeButton from '../../common/ChangeButton/ChangeButton';

import { useSelector } from 'react-redux';

const PlanItem = props => {
    const { item, isAnnually, planType, isLastItem } = props;
    const isTeam = useMemo(() => {
        return planType === 'team';
    }, [planType]);

    const __getDesc = (item, desc, packageType) => {
        const isNeedYear = isAnnually && packageType !== FJViewConstant.FREE;
        switch (item.type) {
            case FJViewConstant.AI_VIDEO_LIMIT:
            case FJViewConstant.AI_IMAGE_LIMIT:
            case FJViewConstant.AI_SCRIPT_LIMIT:
            case FJViewConstant.SUBTITLE_LIMIT:
            case FJViewConstant.AUDIO_REDUCE_LIMIT:
            case FJViewConstant.VOCAL_REMOVER_LIMIT:
            case FJViewConstant.REMOVE_BACKGROUND_LIMIT:
                return (
                    AI_LIMIT[item.type][packageType] * (isNeedYear ? 12 : 1) +
                    '\n' +
                    getDescWithUnit(item.type, isNeedYear, packageType)
                );
            case FJViewConstant.TEXT_TO_SPEECH_LIMIT:
            case FJViewConstant.TRANSLATE_LIMIT:
                return (
                    FJUtil.formatNumber(AI_LIMIT[item.type][packageType] * (isNeedYear ? 12 : 1)) +
                    '\n' +
                    getDescWithUnit(item.type, isNeedYear, packageType)
                );
            default:
                return FJLocalStore._(desc) || <InlineSVG src={CheckIcon} />;
        }
    };

    const getDescWithUnit = (type, isNeedYear, packageType) => {
        const __getStoreText = value => {
            return FJLocalStore._(value + (isTeam && packageType === FJViewConstant.BUSINESS ? '-per-member' : ''));
        };

        switch (type) {
            case FJViewConstant.AI_VIDEO_LIMIT:
                return isNeedYear ? __getStoreText('videos-yr') : __getStoreText('videos-mo');
            case FJViewConstant.AI_IMAGE_LIMIT:
                return isNeedYear ? __getStoreText('generations-yr') : __getStoreText('generations-mo');
            case FJViewConstant.AI_SCRIPT_LIMIT:
                return isNeedYear ? __getStoreText('scripts-yr') : __getStoreText('scripts-mo');
            case FJViewConstant.SUBTITLE_LIMIT:
            case FJViewConstant.AUDIO_REDUCE_LIMIT:
            case FJViewConstant.VOCAL_REMOVER_LIMIT:
                return isNeedYear ? __getStoreText('mins-yr') : __getStoreText('mins-mo');
            case FJViewConstant.REMOVE_BACKGROUND_LIMIT:
                return isNeedYear ? __getStoreText('credits-yr') : __getStoreText('credits-mo');
            case FJViewConstant.TRANSLATE_LIMIT:
            case FJViewConstant.TEXT_TO_SPEECH_LIMIT:
                return isNeedYear ? __getStoreText('characters-yr') : __getStoreText('characters-mo');
            default:
                return '';
        }
    };

    return (
        <>
            {item.type === FJViewConstant.TITLE ? (
                <div
                    className={classNames(
                        Style.plan_title,
                        Style.flex_box,
                        Style.border_line,
                        Style.plan_item,
                        Style.paddingLeft20,
                        Style.titleBgColor,
                    )}
                >
                    {FJLocalStore._(item.totalTitle)}
                </div>
            ) : (
                <div
                    className={classNames(
                        Style.plan_item,
                        Style.flex_box,
                        Style.border_line,
                        isLastItem ? Style.last_item : '',
                    )}
                >
                    <div className={classNames(Style.plan_first, Style.flex_box, Style.plan_line)}>
                        <div className={classNames(Style.plan_title, Style.paddingLeft20, Style.inner_title)}>
                            {FJLocalStore._(item.title)}
                        </div>
                        {item.titleDesc && (
                            <HoverDrop
                                overlay={<div className={Style.question_drop}>{FJLocalStore._(item.titleDesc)}</div>}
                            >
                                <div className={Style.question_icon_box}>
                                    <InlineSVG src={questionIcon} />
                                </div>
                            </HoverDrop>
                        )}
                    </div>
                    <div
                        className={classNames(
                            Style.flex_box,
                            Style.plan_basic,
                            Style.plan_line,
                            isTeam ? Style.plan_basic_team : '',
                        )}
                    >
                        <div className={classNames(Style.plan_text)}>
                            {' '}
                            {__getDesc(item, item.descFree, FJViewConstant.FREE)}
                        </div>
                    </div>
                    {!isTeam ? (
                        <div
                            className={classNames(
                                Style.flex_box,
                                Style.plan_basic,
                                Style.plan_line,
                                isTeam ? Style.plan_basic_team : '',
                            )}
                        >
                            <div className={classNames(Style.plan_text)}>
                                {' '}
                                {__getDesc(item, item.descPlus, FJViewConstant.PLUS)}
                            </div>
                        </div>
                    ) : null}
                    <div className={classNames(Style.flex_box, Style.plan_basic, isTeam ? Style.plan_basic_team : '')}>
                        <div className={classNames(Style.plan_text)}>
                            {' '}
                            {__getDesc(item, item.descBusiness, FJViewConstant.BUSINESS)}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
const FJComparePlans = props => {
    const { changePlan, popupType } = props;

    let discountConf = useSelector(state => state.mainReducer.discountConf);
    let planData = useSelector(state => state.mainReducer.planData);
    let teamSubscription = useSelector(state => state.mainReducer.teamSubscription);
    let currentPlan = useSelector(state => state.mainReducer.currentPlan);
    let isAnnually = useSelector(state => state.mainReducer.isAnnually);
    let planType = useSelector(state => state.mainReducer.planType);

    const useList = useMemo(() => {
        return planType === 'team' ? TeamPlanList : PersonPlanList;
    }, [planType]);

    const isTeam = useMemo(() => {
        return planType === 'team';
    }, [planType]);

    let getDiscount = () => {
        let discount = planType === 'team' ? '33%' : '40%';
        if (discountConf.openDiscount) {
            let lists = discountConf.lists;
            if (lists) {
                let minDiscountChannel;
                for (let i = 0; i < lists.length; i++) {
                    if (lists[i].discountChannel && lists[i].discountChannel !== 0) {
                        if (minDiscountChannel === undefined || lists[i].discountChannel < minDiscountChannel) {
                            minDiscountChannel = 100 - lists[i].discountChannel;
                        }
                    }
                }
                discount = minDiscountChannel + '%';
            }
        }
        return discount;
    };

    let discount = getDiscount();

    let btnClick = index => {
        let btn = document.querySelector(`.CreateTeam${index}`);
        const isGoToEdit = btn.innerText === FJLocalStore._('BUTTON_GET_START');
        btn && btn.click();
        btn &&
            !(window.fjuser && !window.fjuser.isUserLogin()) &&
            !isGoToEdit &&
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
    };

    const getDisplayPlanData = () => {
        if (planType === 'team') {
            return planData.filter(plan => plan.package === 'free' || plan.package === 'team');
        }
        const planDataWithoutTeam = planData.filter(plan => plan.package !== 'team');
        return popupType ? planDataWithoutTeam.filter(plan => plan.package !== 'free') : planDataWithoutTeam;
    };

    const canPlanUpgrade = itemData => {
        let plan = {
            package: itemData.package,
            period: isAnnually ? 'annual' : 'monthly',
        };
        if (planType === 'team') {
            plan.package = plan.package === 'team' ? 'business' : plan.package;
            if (teamSubscription) {
                return window.checkPlanCanUpgrade(teamSubscription, plan);
            }
            return true;
        }
        return window.checkPlanCanUpgrade(currentPlan, plan);
    };

    const getButtonDisplayResult = itemData => {
        if (
            currentPlan.period === 'lifetime' ||
            (currentPlan.status === 'promotion' && currentPlan['period_end'] * 1000 > new Date().getTime())
        ) {
            // 终身版和临时用户都不能购买订阅
            return false;
        }
        // 根据不同的用户类型，展示该套餐上下游套餐可操作状态
        let displayText = '';
        let clickFun = null;

        const jumpToEditorAppFun = () => {
            FJUtil.goToEditorApp('', true);
        };

        if (planType === 'team') {
            // 未登录的情况下
            if (!FJUtil.isLogin()) {
                switch (itemData.package) {
                    case 'free':
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                        break;
                    case 'team':
                        displayText = FJLocalStore._('BUTTON_GET_TEAM');
                        clickFun = btnClick;
                        break;
                }
                return { displayText, clickFun };
            }
            // 当前套餐类型 1. 用户没有创建团队 2. 创建了团队，就自动享有免费团队套餐，且没有购买付费的团队套餐 3. 付费的团队套餐
            if (teamSubscription == null) {
                switch (itemData.package) {
                    case 'free':
                        displayText = FJLocalStore._('create-team');
                        clickFun = btnClick;
                        break;
                    case 'team':
                        displayText = FJLocalStore._('create-team');
                        clickFun = btnClick;
                        break;
                }
                return { displayText, clickFun };
            }

            if (teamSubscription.package === 'free') {
                // 免费团队套餐用户，免费套餐就直接为跳转编辑页，可以升级的套餐就展示为get team;
                displayText =
                    itemData.package === 'free'
                        ? FJLocalStore._('BUTTON_GET_START')
                        : FJLocalStore._('BUTTON_GET_TEAM');
                clickFun = itemData.package === 'free' ? jumpToEditorAppFun : btnClick;
            } else {
                // 有创建团队的情况，就判断当前套餐能否升级， 能升级就显示upgrade，不能就显示get start
                displayText = canPlanUpgrade(itemData)
                    ? FJLocalStore._('UPGRADE_NOW')
                    : FJLocalStore._('BUTTON_GET_START');
                clickFun = canPlanUpgrade(itemData) ? btnClick : jumpToEditorAppFun;
            }

            return { displayText, clickFun };
        }

        // 登录情况下
        if (FJUtil.isLogin()) {
            // 当前按钮的展示结果 = 当前用户类型（free、plus、business） + 当前套餐类型 + 当前套餐是否可以升级
            switch (currentPlan.package) {
                case 'free':
                    if (itemData.package === 'free') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'plus') {
                        displayText = FJLocalStore._('BUTTON_GET_PLUS');
                        clickFun = btnClick;
                    }
                    if (itemData.package === 'business') {
                        displayText = FJLocalStore._('BUTTON_GET_BUSINESS');
                        clickFun = btnClick;
                    }
                    break;
                case 'basic':
                    if (itemData.package === 'free') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'plus') {
                        displayText = canPlanUpgrade(itemData)
                            ? FJLocalStore._('BUTTON_GET_PLUS')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade(itemData) ? btnClick : jumpToEditorAppFun;
                    }
                    if (itemData.package === 'business') {
                        displayText = canPlanUpgrade(itemData)
                            ? FJLocalStore._('BUTTON_GET_BUSINESS')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade(itemData) ? btnClick : jumpToEditorAppFun;
                    }
                    break;
                case 'plus':
                    if (itemData.package === 'free') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'plus') {
                        displayText = canPlanUpgrade(itemData)
                            ? FJLocalStore._('UPGRADE_NOW')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade(itemData) ? btnClick : jumpToEditorAppFun;
                    }
                    if (itemData.package === 'business') {
                        displayText = canPlanUpgrade(itemData)
                            ? FJLocalStore._('BUTTON_GET_BUSINESS')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade(itemData) ? btnClick : jumpToEditorAppFun;
                    }
                    break;
                case 'business':
                    if (itemData.package === 'free') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'plus') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'business') {
                        displayText = canPlanUpgrade(itemData)
                            ? FJLocalStore._('UPGRADE_NOW')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade(itemData) ? btnClick : jumpToEditorAppFun;
                    }
                    break;
            }

            return { displayText, clickFun };
        }

        // 未登录情况下，展示当前套餐的订阅按钮
        return {
            displayText:
                itemData.package === 'free' ? FJLocalStore._('BUTTON_GET_START') : FJLocalStore._(itemData.buttonText),
            clickFun: itemData.package === 'free' ? jumpToEditorAppFun : btnClick,
        };
    };

    // 把头部展示的数据拿来克隆一份，以compare表格复用
    const btnStatus = useMemo(() => {
        const copyHeadDisplayData = getDisplayPlanData();

        if (Array.isArray(copyHeadDisplayData)) {
            let obj = {};
            copyHeadDisplayData.forEach((item, index) => {
                // 根据是否是终身用户确定是否显示该按钮
                if (!getButtonDisplayResult(item)) {
                    obj = Object.assign({}, obj, { [`btn${index}`]: null });
                } else {
                    const text = getButtonDisplayResult(item).displayText;
                    const plain =
                        item.package === 'free' &&
                        (text === FJLocalStore._('BUTTON_GET_START') || text === FJLocalStore._('FREE_BUTTON_SELECT'));
                    obj = Object.assign({}, obj, { [`btn${index}`]: { text, plain } });
                }
            });
            return obj;
        }
    }, [getDisplayPlanData(), teamSubscription, currentPlan, planType, isAnnually, planType]);

    return (
        <div className={classNames(Style.box, { [Style.teamBox]: planType === 'team' })}>
            <div className={Style.title}>{FJLocalStore._('COMPARE_PLANS')}</div>
            <div className={classNames(Style.plan_header, Style.flex_box)}>
                <div className={classNames(Style.plan_title, Style.plan_first, Style.flex_box, Style.paddingLeft20)}>
                    {FJLocalStore._('MONTHLY').charAt(0).toUpperCase() + FJLocalStore._('MONTHLY').slice(1)}
                    <ChangeButton onClick={changePlan} boxClass={Style.checkbox} value={isAnnually} />
                    {FJLocalStore._('ANNUALLY')}

                    <div className={Style.cut_tip_phone}>{FJLocalStore._('SAVE_UP_TO').replace('xxx', discount)}</div>
                </div>
                <div
                    className={classNames(
                        Style.plan_title,
                        Style.plan_basic,
                        isTeam ? Style.plan_basic_team : '',
                        Style.title_and_button,
                    )}
                >
                    {FJLocalStore._('FREE_NAME')}
                    {btnStatus.btn0 && (
                        <button
                            className={classNames(Style.buttonMargin, {
                                [Style.buttonPlain]: btnStatus.btn0 && btnStatus.btn0.plain,
                            })}
                            onClick={() => {
                                btnClick(0);
                            }}
                        >
                            {(btnStatus.btn0 && btnStatus.btn0.text) || FJLocalStore._('BUTTON_GET_START')}
                        </button>
                    )}
                </div>
                {isTeam ? null : (
                    <div
                        className={classNames(
                            Style.plan_title,
                            Style.flex_box,
                            Style.plan_basic,
                            Style.title_and_button,
                        )}
                    >
                        {FJLocalStore._('PLUS_NAME')}
                        {btnStatus.btn1 && (
                            <button
                                className={classNames(
                                    classNames(Style.buttonMargin, {
                                        [Style.buttonPlain]: btnStatus.btn1 && btnStatus.btn1.plain,
                                    }),
                                )}
                                onClick={() => {
                                    btnClick(1);
                                }}
                            >
                                {(btnStatus.btn1 && btnStatus.btn1.text) || FJLocalStore._('BUTTON_GET_START')}
                            </button>
                        )}
                    </div>
                )}
                <div
                    className={classNames(
                        Style.plan_title,
                        Style.flex_box,
                        Style.plan_basic,
                        isTeam ? Style.plan_basic_team : '',
                        Style.title_and_button,
                    )}
                >
                    {planType === 'team' ? FJLocalStore._('TEAM_NAME') : FJLocalStore._('BUSINESS_NAME')}
                    {btnStatus[isTeam ? 'btn1' : 'btn2'] && (
                        <button
                            className={classNames(
                                classNames(Style.buttonMargin, {
                                    [Style.buttonPlain]:
                                        btnStatus[isTeam ? 'btn1' : 'btn2'] &&
                                        btnStatus[isTeam ? 'btn1' : 'btn2'].plain,
                                }),
                            )}
                            onClick={() => {
                                btnClick([isTeam ? 1 : 2]);
                            }}
                        >
                            {(btnStatus[isTeam ? 'btn1' : 'btn2'] && btnStatus[isTeam ? 'btn1' : 'btn2'].text) ||
                                FJLocalStore._('BUTTON_GET_TEAM')}
                        </button>
                    )}
                </div>
            </div>
            {useList.map((item, index) => {
                return (
                    <PlanItem
                        item={item}
                        key={index}
                        isAnnually={isAnnually}
                        planType={planType}
                        isLastItem={index === useList.length - 1}
                    />
                );
            })}
        </div>
    );
};
export default FJComparePlans;
