import FJCreditsPackageView from './FJCreditsPackageView';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../../reducer/action/mainAction';

const FJCreditsPackageViewContainer = () => {
    const dispatch = useDispatch();
    const purchase = useSelector(state => state.mainReducer.purchase);
    const creditsPayData = useSelector(state => state.mainReducer.creditsPayData);
    const method = useSelector(state => state.mainReducer.method);

    const changeSelectCredit = index => {
        dispatch(
            mainAction.changeCheckedCredits({
                index,
                info: purchase[index],
            }),
        );
    };

    return (
        <FJCreditsPackageView
            purchase={purchase}
            creditsPayData={creditsPayData}
            changeSelectCredit={changeSelectCredit}
            pageMethod={method}
        />
    );
};
export default FJCreditsPackageViewContainer;
