import { useSelector } from 'react-redux';

export default function usePricePrefix() {
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountry = useSelector(state => state.mainReducer.currentCountry);
    if (currentCountry === 'EU' || currentCountry === 'IN') {
        return currentCountryMonetary;
    } else {
        return currentCountry + (currentCountryMonetary || '$');
    }
}
