import React, { Component } from 'react';

import FJUtil from '../../../../util/FJUtil';

class MovingList extends Component {
    constructor(props) {
        super(props);
        this.swiperContainer = null;
        this.clientX = null;

        // 记录移动元素的本来大小
        this.containerWidth = 0;

        this.swiperContainerStyle = {};

        this.moveDistance = 0;
        this.state = {
            marginLeft: 0, // 初始时 li 元素的左边距为 0
            isPaused: false,

            containerClick: false,
            displayData: [],

            complementDom: [],
            leftComplementDom: [],
            rightComplementDom: [],
            childAllowClick: true,
        };
    }

    componentDidMount() {
        // const { list } = this.props;
        // this.setState({
        //   displayData: list
        // }, () => {
        //
        // })

        setTimeout(() => {
            this.calculateVisibleItems();
        });
    }

    componentWillUnmount() {
        // 组件卸载时清除定时器
        clearInterval(this.timer);
    }

    changeState = () => {
        const { direction = 'forward' } = this.props;

        if (direction === 'forward') {
            this.setState(prevState => {
                const nextMarginLeft = prevState.marginLeft + 100;

                const marginLeft = nextMarginLeft > 0 ? nextMarginLeft - this.containerWidth - 100 : nextMarginLeft;
                return {
                    marginLeft,
                    isPaused: nextMarginLeft > 0,
                };
            });
        } else {
            this.setState(prevState => {
                const nextMarginLeft = prevState.marginLeft - 100;

                const marginLeft =
                    nextMarginLeft + this.containerWidth < 0 ? nextMarginLeft + this.containerWidth : nextMarginLeft;

                return {
                    marginLeft,
                    isPaused: nextMarginLeft + this.containerWidth < 0,
                };
            });
        }
    };

    startAnimation = () => {
        const { intervalTime = 1 } = this.props;
        this.timer = setInterval(() => {
            if (this.swiperContainer) {
                this.swiperContainerStyle = this.swiperContainer.getBoundingClientRect();
                if (!this.state.isPaused) {
                    this.setState({
                        isPaused: false,
                    });
                }
                // 每秒更新一次 marginLeft 的值，使 li 元素每秒向右移动 100px
                this.changeState();
            }
        }, intervalTime * 1000); // 每秒移动100px
    };

    __drawList() {
        const { list, defaultSlot } = this.props;
        // return Array.isArray(this.state.displayData) && this.state.displayData.map((item, index) => {
        //   return defaultSlot ? defaultSlot(item, index, this.childAllowClick) : '';
        // })

        return (
            Array.isArray(list) &&
            list.map((item, index) => {
                return defaultSlot ? defaultSlot(item, index, this.state.childAllowClick, this.closeCallBack) : '';
            })
        );
    }

    // 鼠标移入事件
    onMouseEnter = () => {
        clearInterval(this.timer);
        const rec = this.swiper.getBoundingClientRect();

        this.setState({
            isPaused: true,
            marginLeft: rec ? Math.ceil(rec.left) : this.state.marginLeft,
        });
    };

    // 鼠标移出事件
    handleMouseLeave = () => {
        this.setState({ isPaused: false }, () => {
            if (!this.state.containerClick) {
                clearTimeout(this.timer);
                this.startAnimation();
            } else {
                this.setState({
                    containerClick: false,
                });
            }
        });
    };

    // 当点击模版时，暂停动画滚动，关闭弹窗时，不恢复动画，当重新移入移出的时候，重启动画
    containerClick = e => {
        this.setState({
            containerClick: true,
        });
        e.stopPropagation();
        e.preventDefault();
    };

    // 点击关闭全屏后，重新启动动画
    closeCallBack() {
        clearTimeout(this.timer);
        this.startAnimation();
    }

    // 通过计算拿到需要补足的dom元素，给动画补充元素
    calculateVisibleItems = () => {
        // 父容器（布局容器）
        // const container = this.swiperContainer.getBoundingClientRect()
        const { width: containerWidth } = window.getComputedStyle(this.swiperContainer);
        const items = this.swiper.childNodes;

        // 获取滚动的最大距离
        const containerStyle = window.getComputedStyle(this.swiper);

        // 元素移动最大距离，用于重置起始位置
        this.containerWidth = parseFloat(containerStyle.width);

        if (parseFloat(containerStyle.width) <= parseFloat(containerWidth)) {
            return;
        }

        // const firstChild = items[0];

        let totalWidth = 0;

        const { direction = 'forward', list } = this.props;

        let complementDom = [];
        if (direction === 'forward') {
            // 将最前面的元素补充到最后面去

            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const getComputedStyle = window.getComputedStyle(item);
                totalWidth +=
                    item.offsetWidth + parseInt(getComputedStyle.marginRight) + parseInt(getComputedStyle.marginLeft);

                if (totalWidth < parseFloat(containerWidth) + item.offsetWidth) {
                    complementDom.push(list[i]);
                } else {
                    break;
                }
            }
        } else {
            // 将最后面的元素补充到最前面来

            for (let i = 0; i < items.length - 1; i++) {
                const item = items[i];
                const getComputedStyle = window.getComputedStyle(item);
                totalWidth += item.offsetWidth + parseInt(getComputedStyle.marginRight + getComputedStyle.marginLeft);
                if (totalWidth < parseFloat(containerWidth) + item.offsetWidth) {
                    complementDom.push(list[i]);
                } else {
                    break;
                }
            }
        }

        this.setState({
            complementDom: complementDom,
        });

        clearTimeout(this.timer);
        this.startAnimation();
    };

    __drawComplementDom = () => {
        const { list, defaultSlot } = this.props;

        return this.state.complementDom.map((item, index) => {
            return defaultSlot(item, list.length + index, this.state.childAllowClick, this.closeCallBack);
        });
    };

    // 判断鼠标是否在目标元素内
    isMouseInElement(event) {
        const rect = this.swiperContainerStyle;
        const mouseX = FJUtil.isMobile() ? event.touches[0].clientX : event.clientX;
        const mouseY = FJUtil.isMobile() ? event.touches[0].clientY : event.clientY;
        return (
            mouseX - 5 >= rect.left &&
            mouseX + 5 <= rect.left + rect.width &&
            mouseY - 5 >= rect.top &&
            mouseY + 5 <= rect.bottom
        );
    }

    // 鼠标拖动事件
    downFun = e => {
        e.preventDefault();
        e.stopPropagation();

        this.FJJSWwiperMove = true;
        this.swiperContainerStyle = this.swiperContainer.getBoundingClientRect();

        this.clientX = FJUtil.isMobile() ? (e.touches ? e.touches[0].clientX : e.clientX) : e.clientX;
        const eventName1 = FJUtil.isMobile() ? 'touchmove ' : 'mousemove';
        const eventName2 = FJUtil.isMobile() ? 'touchend' : 'mouseup';
        const params = FJUtil.isMobile() ? { passive: true } : false;

        FJUtil.addEvent(this.swiperContainer, eventName1, this.moveFun, params);
        FJUtil.addEvent(this.swiperContainer, eventName2, this.upFun, params);
    };

    touchStart = e => {
        this.onMouseEnter();

        this.FJJSWwiperMove = true;
        this.swiperContainerStyle = this.swiperContainer.getBoundingClientRect();

        this.clientX = FJUtil.isMobile() ? (e.touches ? e.touches[0].clientX : e.clientX) : e.clientX;
    };

    moveFun = e => {
        if (!this.FJJSWwiperMove) {
            return;
        }
        this.setState({
            childAllowClick: false,
        });
        this.moveDistance =
            (FJUtil.isMobile() ? (e.touches ? e.touches[0].clientX : e.clientX) : e.clientX) -
            this.clientX +
            this.state.marginLeft;
        this.swiper.style.transform = `translateX(${this.moveDistance}px)`;

        if (!this.isMouseInElement(e)) {
            this.upFun(e);
        }
    };

    upFun = () => {
        // 先判断是否鼠标拖拽超出，超出了就移动回来
        this.resetDragPosition();

        const rec = this.swiper.getBoundingClientRect();
        this.setState({
            marginLeft: rec ? rec.left : this.moveDistance,
        });

        const eventName = FJUtil.isMobile() ? 'touchmove' : 'mousemove';
        const params = FJUtil.isMobile() ? { passive: true } : false;
        FJUtil.removeEvent(this.swiperContainer, eventName, this.moveFun, params);
        this.FJJSWwiperMove = false;
        setTimeout(() => {
            this.setState({
                childAllowClick: true,
            });
        }, 0);
    };

    // 重置拖动位置，防止超出边界
    resetDragPosition = () => {
        // 拿到没有增加子元素时的全部内容长度 this.containerWidth
        // 拿到元素滚动距离

        const { direction = 'forward' } = this.props;
        if (direction === 'forward') {
            if (this.moveDistance > 0) {
                this.swiper.style.transform = `translateX(-${this.containerWidth}px)`;
            }

            if (this.moveDistance + this.containerWidth < 0) {
                this.swiper.style.transform = `translateX(${this.moveDistance + this.containerWidth}px)`;
            }
        } else {
            if (this.moveDistance + parseFloat(this.containerWidth) <= 0) {
                this.swiper.style.transform = `translateX(${this.moveDistance + this.containerWidth}px)`;
            }

            if (this.moveDistance > 0) {
                this.swiper.style.transform = `translateX(-${this.containerWidth}px)`;
            }
        }
    };

    render() {
        const { isPaused, direction = 'forward' } = this.state;
        const { intervalTime = 1 } = this.props;
        return (
            <div
                style={{ width: '100%', overflow: 'hidden', boxSizing: 'content-box' }}
                ref={e => (this.swiperContainer = e)}
            >
                <div
                    onClick={this.containerClick}
                    onMouseDown={this.downFun}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    style={{
                        display: 'flex',
                        width: 'max-content',
                        transform: `translateX(${this.state.marginLeft}px)`,
                        transition: isPaused ? 'none' : `transform ${intervalTime}s linear`,
                    }}
                    ref={e => (this.swiper = e)}
                >
                    {direction !== 'forward' && this.__drawComplementDom()}
                    {this.__drawList()}
                    {direction === 'forward' && this.__drawComplementDom()}
                </div>
            </div>
        );
    }
}

export default MovingList;
