import Style from './ClientsItem.module.less';

import Start from '../../../../icon/start-5.png';

export default function ClientsItem(props) {
    const { item } = props;
    return (
        <div className={Style.itemBox}>
            <p className={Style.desc}>{item.desc}</p>
            <div className={Style.person}>
                <img
                    className={Style.head}
                    src={item.img}
                    alt={item.name + 'photo'}
                    width={68}
                    height={68}
                    loading='lazy'
                />
                <span className={Style.personName}>{item.name}</span>
                <img src={Start} alt='Clients Score Start' width={80} height={12} loading='lazy' />
            </div>
        </div>
    );
}
