import Style from './ChangeButton.module.less';
import classNames from 'classnames';

const ChangeButton = props => {
    let { value, onClick, boxClass = '' } = props;
    let _onClick = () => {
        onClick(!value);
    };
    return (
        <div className={classNames(Style.btnBox, boxClass)} onClick={_onClick}>
            <div className={classNames(Style.circle, value ? Style.open : Style.close)} />
        </div>
    );
};
export default ChangeButton;
