import { useDispatch, useSelector } from 'react-redux';
import FJPayment from './FJPayment';
import mainAction from '../../../reducer/action/mainAction';
import FJMobilePayAction from '../../../reducer/action/FJMobilePayAction';
import FJViewConstant from '../../../constant/FJViewConstant';
import FJStripePayAction from '../../../reducer/action/FJStripePayAction';
import { useEffect } from 'react';

const FJPaymentContainer = () => {
    const dispatch = useDispatch();
    const creditsPayData = useSelector(state => state.mainReducer.creditsPayData);
    const payMethod = useSelector(state => state.mainReducer.payMethod);
    const dataInfo = useSelector(state => state.mainReducer.dataInfo);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);
    const method = useSelector(state => state.mainReducer.method);
    const loadStripeUIStatus = useSelector(state => state.mainReducer.loadStripeUIStatus);
    const mobilePayStep = useSelector(state => state.mainReducer.mobilePayStep);

    const _changePayMethod = method => {
        dispatch(mainAction.changePayMethodAction(method));
    };
    const onRedeemCoupon = code => {
        dispatch(FJMobilePayAction.redeemCouponAction(code));
    };
    const gotoBack = () => {
        if (method === 'popup') {
            dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.CREDITS_CENTER));
        } else {
            dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.NONE));
        }
    };
    const onPayPalInCredit = () => {
        dispatch(FJMobilePayAction.paypalInCreditAction());
    };
    const stripePayInCredit = () => {
        dispatch(FJStripePayAction.stripePayInCreditsAction({}));
    };
    useEffect(() => {
        // 重置优惠券
        if (mobilePayStep === FJViewConstant.PAYMENT_METHOD) {
            dispatch(mainAction.setDataInfo({ appliedCoupon: null }));
        }
    }, [mobilePayStep, dispatch]);
    // 更新支付组件（未登录到登陆步骤，不能提前加载支付组件）
    if (mobilePayStep !== FJViewConstant.PAYMENT_METHOD) return null;
    return (
        <FJPayment
            creditsPayData={creditsPayData}
            payMethod={payMethod}
            changePayMethod={_changePayMethod}
            couponInfo={dataInfo}
            exchangeRate={exchangeRate}
            onRedeemCoupon={onRedeemCoupon}
            gotoBack={gotoBack}
            onPayPalInCredit={onPayPalInCredit}
            loadStripeUIStatus={loadStripeUIStatus}
            stripePayInCredit={stripePayInCredit}
        />
    );
};
export default FJPaymentContainer;
