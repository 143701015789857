import { Component } from 'react';

import Style from './FJEntrance.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import FJUtil from '../../util/FJUtil';
import InlineSvg from 'react-inlinesvg';
import arrowSvg from '../../icon/arrow-right.svg';

export default class FJEntrance extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            title = FJLocalStore._('entrance-title'),
            buttonText = FJLocalStore._('StartNow'),
            url = FJUtil.getCurrentUrl() + '/editor/app?ratio=landscape',
            img = 'https://resource.flexclip.com/pages/home/home-footer2.webp?v=202447',
            bgImg = 'https://resource.flexclip.com/pages/home/bg-home-footer.webp',
        } = this.props;

        let style = {};
        if (bgImg) {
            style = {
                backgroundImage: 'url(' + bgImg + ')',
            };
        }
        return (
            <div className={Style.entrance} style={style}>
                <h2 className={Style.title}>{title}</h2>
                <button
                    onClick={() => {
                        window.location.href = url;
                    }}
                    className={Style.scratchBtn}
                >
                    {buttonText}
                    <InlineSvg src={arrowSvg} />
                </button>

                <div className={Style.entranceImgBox}>
                    <img
                        src={img}
                        className={Style.entranceImg}
                        width={1236}
                        height={551}
                        loading='lazy'
                        alt='jump to flexclip propaganda picture'
                    />
                </div>
            </div>
        );
    }
}
