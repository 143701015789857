import { useSelector } from 'react-redux';
import FJViewConstant from '../../constant/FJViewConstant';
import useIsMobile from '../../hooks/useIsMobile';
import FJMobileBG from '../common/FJMobileBG/FJMobileBG';
import FJCreditsPurchasePanelContainer from './FJCreditsPurchasePanel/FJCreditsPurchasePanel.container';
import FJPaymentContainer from './FJPayment/FJPayment.container';
import FJPaySuccessContainer from './FJPaySuccess/FJPaySuccess.container';
import { useEffect } from 'react';
const FJMobile = () => {
    const isMobile = useIsMobile();
    const mobilePayStep = useSelector(state => state.mainReducer.mobilePayStep);
    useEffect(() => {
        if (!isMobile) return;

        // 禁止用户缩放
        // 创建一个新的meta元素
        const meta = document.createElement('meta');
        // 设置meta元素的属性
        meta.setAttribute('name', 'viewport');
        meta.setAttribute(
            'content',
            'width=device-width, initial-scale=1.0,minimum-scale=1, maximum-scale=1.0, user-scalable=no,viewport-fit=cover',
        );
        // 将meta元素添加到head中
        document.head.appendChild(meta);
    }, [isMobile]);
    if (!isMobile) return null;

    return (
        <>
            <FJMobileBG active={mobilePayStep === FJViewConstant.CREDITS_CENTER}>
                <FJCreditsPurchasePanelContainer />
            </FJMobileBG>
            <FJMobileBG active={mobilePayStep === FJViewConstant.PAYMENT_METHOD}>
                <FJPaymentContainer />
            </FJMobileBG>
            <FJMobileBG active={mobilePayStep === FJViewConstant.PAY_SUCCESS}>
                <FJPaySuccessContainer />
            </FJMobileBG>
        </>
    );
};
export default FJMobile;
