import classNames from 'classnames';
import styles from './FJMobileBG.module.less';

const FJMobileBG = props => {
    const { style, children, active, className, direction = 'up' } = props;
    const classBtn = classNames(
        styles.content,
        className,
        active ? styles.active : null,
        direction === 'up' && active ? styles.up : null,
        direction === 'down' && active ? styles.down : null,
    );

    return (
        <div style={style} className={classBtn}>
            {children}
        </div>
    );
};

export default FJMobileBG;
