import Credits from './Credits/Credits';
import PayPanelCredits from '../PayPanelCredits/PayPanelCredits';

import { useSelector, useDispatch } from 'react-redux';
import mainAction from '../../reducer/action/mainAction';

import FJNetworkStore from '../../store/FJNetworkStore';
import EndPanelCredits from '../EndPanelCredits/EndPanelCredits';
import FJMessageStore from '../../store/FJMessageStore';
import FJLocalStore from '../../store/FJLocalStore';

import Styles from './CreditsContainer.module.less';
import FJUtil from '../../util/FJUtil';
import FJMobilePayAction from '../../reducer/action/FJMobilePayAction';
import FJViewConstant from '../../constant/FJViewConstant';

export default function CreditsContainer() {
    const dispatch = useDispatch();
    const openCreditsPayPanel = useSelector(state => state.mainReducer.openCreditsPayPanel);
    const purchase = useSelector(state => state.mainReducer.purchase);
    const showCreditsEndPanel = useSelector(state => state.mainReducer.showCreditsEndPanel);

    const init = () => {
        // 隐藏其他不用展示的元素
        if (document.getElementById('pricing-view-container')) {
            document.getElementById('pricing-view-container').style.display = 'none';
            // document.getElementById('pricing-view-container').style.display = 'block';
        }

        // 如果当前用户没有登录/注册，则先让用户进行登录或者注册
        if (!FJUtil.checkIsLogin() && !FJUtil.isLocalhost()) {
            return;
        }
        if (FJUtil.isMobile()) {
            dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.PAYMENT_METHOD));
            return;
        }
        dispatch(mainAction.changeLoadingStatusAction(true));
        FJNetworkStore.getCardInfo()
            .then(response => {
                // response 数据结构
                // pp_email:""
                // ss_customer:"cus_Q48MkSGO7yVKyY"
                // ss_expired:"4/2029"
                // ss_last4:"4242"
                // uid:151279
                // user_info

                let subscription =
                    typeof response.data === 'object' ? (Array.isArray(response.data) ? {} : response.data) : null;

                if (!subscription) {
                    return window.fjmessage && window.fjmessage.addMessage(response.msg || 'request err', 'error');
                }

                dispatch(
                    mainAction.setCurrentPlan({
                        last4: subscription.ss_last4,
                    }),
                );

                dispatch(mainAction.changeLoadingStatusAction(false));
                // 将头部的两个切换按钮进行显示、隐藏切换
                dispatch(mainAction.showOrHiddenPanelButton(false));

                // 打开支付面板
                dispatch(mainAction.openOrCloseCreditsPayPanel(true));
            })
            .catch(error => {
                FJMessageStore.addMessage(FJLocalStore._('failed-to-connect-network'), 'error');
            })
            .finally(() => {
                dispatch(mainAction.changeLoadingStatusAction(false));
            });
    };
    // 将选中的积分信息存到redux中
    const changeCheckedCredits = index => {
        dispatch(
            mainAction.changeCheckedCredits({
                index: index,
                info: purchase[index],
            }),
        );
    };
    const showPayPanelCredits = openCreditsPayPanel && !showCreditsEndPanel;
    const showCredits = !openCreditsPayPanel;

    return (
        <div>
            {/* 展示积分面板还是积分购买的支付面板 */}
            {showPayPanelCredits && <PayPanelCredits purchase={purchase} />}
            <div className={Styles.marginBox}>
                {/* 积分展示面板 */}
                {showCredits && (
                    <Credits purchase={purchase} getUserInfo={init} changeCheckedCredits={changeCheckedCredits} />
                )}
                {/* 购买积分成功后展示问卷页面 */}
                {showCreditsEndPanel && <EndPanelCredits />}
            </div>
        </div>
    );
}
