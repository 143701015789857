import FJMobileHeader from '../FJMobileHeader/FJMobileHeader';
import FJLocalStore from '../../../store/FJLocalStore';
import FJMobileFooter from '../FJMobileFooter/FJMobileFooter';
import styles from './FJPayment.module.less';
import InlineSvg from 'react-inlinesvg';
import CreditsPurpleSvg from '../../../icon/credit-purple.svg';
import AddSvg from '../../../icon/add.svg';
import GiftSvg from '../../../icon/gift.svg';
import ButtonArrowSvg from '../../../icon/button-arrow.svg';
import MobileCardSvg from '../../../icon/mobile-card.svg';
import MobilePaypalSvg from '../../../icon/mobile-paypal.svg';
import bannerNext from '../../../assets/mobileBase64/banner-next';
import FJCheckBox from '../../../components/common/FJCheckBox/FJCheckBox';
import FJMobileInput from '.././../../components/common/FJMobileInput/FJMobileInput';
import FJUtil from '../../../util/FJUtil';
import { useMemo, useRef } from 'react';
import useRealPayPrice from '../../../hooks/useRealPayPrice';
import FJStripePayContainer from '../../FJStripePay/container/FJStripePayContainer';
import classNames from 'classnames';
import FJViewConstant from '../../../constant/FJViewConstant';
import FJMessageStore from '../../../store/FJMessageStore';
import { useAmount } from '../../../hooks/useAmount';
import usePricePrefix from '../../../hooks/usePricePrefix';
const PAY_LIST = [
    { icon: MobilePaypalSvg, type: 'paypal', text: 'PayPal' },
    { icon: MobileCardSvg, type: 'stripe', text: 'digital-wallets' },
];

const FJPayment = props => {
    const {
        creditsPayData,
        payMethod,
        changePayMethod,
        couponInfo,
        exchangeRate,
        onRedeemCoupon,
        gotoBack,
        onPayPalInCredit,
        loadStripeUIStatus,
        stripePayInCredit,
    } = props;
    const purchaseInfo = creditsPayData.info;

    const payInfo = creditsPayData.info;
    const _inputRef = useRef(null);
    const _stripeRef = useRef(null);

    const showEditCoupon = e => {
        _inputRef.current.show();
    };
    const onSubmitCoupon = code => {
        onRedeemCoupon(code);
    };
    const _handlePrice = price => {
        return FJUtil.formatNumberThousandth(FJUtil.preciseMultiply([parseFloat(price), exchangeRate]).toFixed(2));
    };
    const originPrice = useMemo(() => {
        return creditsPayData?.info?.price || 0;
    }, [creditsPayData]);
    const isAppliedCoupon = useMemo(() => {
        return couponInfo.appliedCoupon;
    }, [couponInfo]);
    const realPrice = useMemo(() => {
        if (couponInfo.appliedCoupon && couponInfo.couponObject) {
            let amount = creditsPayData.info.price;
            if (couponInfo.couponObject.amount_off) {
                amount = amount - couponInfo.couponObject.amount_off;
                amount = amount.toFixed(2);
            } else if (couponInfo.couponObject.percent_off) {
                amount = (1 - parseFloat(couponInfo.couponObject.percent_off) / 100) * amount;
                amount = amount.toFixed(2);
            }

            if (amount < 0) amount = 0;
            return amount;
        } else {
            return originPrice;
        }
    }, [originPrice, creditsPayData, couponInfo]);
    useRealPayPrice(realPrice);

    const priceUnitPrefix = usePricePrefix();

    const _onPayNow = () => {
        if (!purchaseInfo || !purchaseInfo.packageName) {
            return;
        }
        if (!FJUtil.checkIsLoginByNetwork()) {
            return;
        }
        if (payMethod === 'paypal') {
            onPayPalInCredit();
        } else {
            if (couponInfo.freeOfCharge) {
                stripePayInCredit();
            } else {
                if (loadStripeUIStatus !== FJViewConstant.SUCCESS) {
                    FJMessageStore.addMessage(FJLocalStore._('network-error-please-try-again-later'), 'error');
                    return;
                }
                _stripeRef.current.handleSubmit(purchaseInfo.packageName, {
                    type: FJViewConstant.CREDITS,
                });
            }
        }
    };
    const amount = Math.floor(FJUtil.preciseMultiply([parseFloat(realPrice), 1]).toFixed(2) * 100);
    useAmount(amount);
    const renderPayInfo = () => {
        return (
            <div className={styles.pay_info}>
                {isAppliedCoupon && (
                    <div className={styles.pay_info_coupon}>
                        {priceUnitPrefix + _handlePrice(originPrice)}
                        <div className={styles.line} />
                    </div>
                )}
                <div className={styles.title}>{priceUnitPrefix + _handlePrice(realPrice)}</div>
                <div className={styles.info_box}>
                    <InlineSvg src={CreditsPurpleSvg} />
                    <span className={styles.info_num}>{payInfo.base}</span>
                    {payInfo.present && (
                        <>
                            <InlineSvg src={AddSvg} className={styles.add_svg} />
                            <InlineSvg src={GiftSvg} />
                            <span className={styles.info_num}>{payInfo.present}</span>
                        </>
                    )}
                </div>
            </div>
        );
    };
    const renderCoupon = () => {
        return (
            <div className={styles.coupon} onClick={showEditCoupon}>
                <div className={styles.coupon_title}>{FJLocalStore._('coupon-code')}</div>
                <div className={styles.coupon_right}>
                    {isAppliedCoupon && (
                        <div className={styles.coupon_view}>
                            {'- ' + priceUnitPrefix + _handlePrice(originPrice - realPrice)}
                        </div>
                    )}
                    <InlineSvg src={ButtonArrowSvg} />
                </div>
                <FJMobileInput
                    ref={_inputRef}
                    title={FJLocalStore._('coupon-code')}
                    placeholder={FJLocalStore._('enter-coupon-code')}
                    onSubmit={onSubmitCoupon}
                />
            </div>
        );
    };
    const renderPayMethod = () => {
        return (
            <div className={styles.pay_method}>
                <div className={styles.pay_method_title}>{FJLocalStore._('payment-methods')}</div>
                {PAY_LIST.map(item => (
                    <div
                        className={styles.pay_item}
                        key={item.text}
                        onClick={() => {
                            changePayMethod(item.type);
                        }}
                    >
                        <div className={styles.pay_item_left}>
                            <InlineSvg src={item.icon} />
                            <span className={styles.pay_item_text}>{FJLocalStore._(item.text)}</span>
                        </div>
                        <FJCheckBox active={item.type === payMethod} />
                    </div>
                ))}
            </div>
        );
    };

    // const price = purchaseInfo ? purchaseInfo.priceUnit + realPrice : '';
    const price = priceUnitPrefix + _handlePrice(realPrice);
    const btnText = FJLocalStore._('pay-now') + ' ' + price;
    // 100%折扣码判断
    const isShowFrom = !couponInfo.appliedCoupon || !couponInfo.freeOfCharge;
    return (
        <div className={styles.box} style={{ backgroundImage: `url(${bannerNext})` }}>
            <FJMobileHeader title={FJLocalStore._('Payment-Details')} gotoBack={gotoBack} />
            {renderPayInfo()}
            {renderCoupon()}
            {renderPayMethod()}
            {isShowFrom && (
                <FJStripePayContainer
                    ref={_stripeRef}
                    mode='payment'
                    boxClassName={classNames(styles.stripe_pay, payMethod === 'stripe' && styles.show)}
                />
            )}
            <FJMobileFooter text={btnText} onClick={_onPayNow} />
        </div>
    );
};
export default FJPayment;
