import FJMessageStore from '../../store/FJMessageStore';
import FJUtil from '../../util/FJUtil';
import mainAction from './mainAction';
import FJLocalStore from '../../store/FJLocalStore';
import FJNetworkStore from '../../store/FJNetworkStore';
import FJActionConstant from '../../constant/FJActionConstant';

export default {
    redeemCouponAction: function (coupon) {
        return (dispatch, getState) => {
            const { creditsPayData, currentCountryMonetaryISO } = getState().mainReducer;
            dispatch(mainAction.changeLoadingStatusAction(true));
            window.FJGlobalariable.modSubscription.network
                .redeemPointCoupon(coupon, creditsPayData.info.packageName, currentCountryMonetaryISO)
                .then(res => {
                    switch (res.code) {
                        case 200:
                            if (res.data.allow_coupon) {
                                // 展示预估价格
                                window.FJGlobalariable.modSubscription.state.appliedCoupon = coupon;
                                dispatch(
                                    mainAction.setDataInfo({
                                        appliedCoupon: coupon, // 设置应用后的coupon
                                        coupon: coupon,
                                        couponObject: res.data.coupon_object,
                                        estimateAmount: res.data,
                                        freeOfCharge: res.data.free_of_charge,
                                    }),
                                );
                            } else {
                                // 不可使用coupon
                                // dataInfo.appliedCoupon = null;
                                dispatch(
                                    mainAction.setDataInfo({
                                        appliedCoupon: null,
                                    }),
                                );
                                FJMessageStore.addMessage(FJLocalStore._('COM_FJ_SP_COUPON_CONTINUOUS'), 'warning');
                            }
                            break;
                        case 214:
                            FJUtil.checkLogin(res.code);
                            break;
                        case 335:
                            FJUtil.checkLogin(res.code);
                            break;
                        default:
                            dispatch(
                                mainAction.setDataInfo({
                                    appliedCoupon: null,
                                }),
                            );
                            if (res.msg === 'SUB.COM_FJ_SP_COUPON_MATCH_PLAN_ERROR') {
                                let list = res.data.split(',');
                                list = list.map(item => {
                                    // 将拆分之后的元素筛除掉''
                                    let arr = item.split(' ').filter(splitItem => splitItem.length > 0);
                                    arr = arr.map(arrItem => {
                                        // 将字符串前后的空格删除之后去匹配本地化
                                        return FJLocalStore._(arrItem.trim().toUpperCase());
                                    });
                                    return arr.join('');
                                });
                                let MSG = res.msg.replace('SUB.', '');
                                if (window.text_printf) {
                                    FJMessageStore.addMessage(
                                        window.text_printf(FJLocalStore._(MSG), list.join(', ')),
                                        'warning',
                                    );
                                }
                            } else if (res.msg === 'SUB.CANNOT_USED_FOR_UPGRADE') {
                                FJMessageStore.addMessage(FJLocalStore._('CANNOT_USED_FOR_UPGRADE'), 'warning');
                            } else if (res.msg === 'SUB.COM_FJ_SP_COUPON_NOT_ENOUGH_CURRENCY') {
                                FJMessageStore.addMessage(FJLocalStore._('the-code-only-applies-to-payments'), 'error');
                            } else {
                                let MSG = res.msg.replace('SUB.', '');
                                if (window.text_) {
                                    FJMessageStore.addMessage(window.text_(FJLocalStore._(MSG)), 'warning');
                                }
                            }
                    }
                })
                .catch(err => {
                    // dataInfo.appliedCoupon = null;
                    dispatch(
                        mainAction.setDataInfo({
                            appliedCoupon: null,
                        }),
                    );
                    FJMessageStore.addMessage(FJLocalStore._('COM_FJ_SP_COUPON_NOT_EXIST'), 'warning');
                    console.log(err);
                })
                .finally(() => {
                    dispatch(mainAction.changeLoadingStatusAction(false));
                });
        };
    },
    updateCreditsNumAction: function () {
        return async dispatch => {
            const { code, data } = await FJNetworkStore.getCreditsInfo();
            if (code === 200) {
                dispatch(this.setCreditsNum(data.total));
            }
        };
    },
    setCreditsNum: function (data) {
        return {
            type: FJActionConstant.SET_CREDITS_NUM,
            data,
        };
    },
    setMobilePayStep: function (data) {
        return {
            type: FJActionConstant.SET_MOBILE_PAY_STEP,
            data,
        };
    },
    paypalInCreditAction: function () {
        return (dispatch, getState) => {
            const { creditsPayData, currentCountryMonetaryISO, dataInfo } = getState().mainReducer;
            const purchaseInfo = creditsPayData.info;
            dispatch(mainAction.changeLoadingStatusAction(true));
            const paypalWindow = window.open(
                '',
                '_blank',
                'height=700,width=500,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
            );
            window.FJGlobalariable.modSubscription.network
                .getPayPalCreditsWindowUrl(purchaseInfo.packageName, dataInfo.appliedCoupon, currentCountryMonetaryISO)
                .then(response => {
                    if (response.code === 200 && response.data?.approvalUrl) {
                        return new Promise((resolve, reject) => {
                            paypalWindow.location.href = response.data.approvalUrl;
                            paypalWindow.opener = window;
                            const loop = setInterval(() => {
                                try {
                                    if (paypalWindow.closed) {
                                        clearInterval(loop);
                                        resolve();
                                    }
                                } catch (e) {
                                    clearInterval(loop);
                                    reject(e);
                                }
                            }, 100);
                        });
                    } else {
                        paypalWindow && paypalWindow.close && paypalWindow.close();
                        throw new Error(response.code);
                    }
                })
                .catch(error => {
                    if (error?.message === '429') {
                        FJMessageStore.addMessage(FJLocalStore._('FAIL_WITH_TOO_MANY_REQUESTS'), 'error');
                    } else {
                        FJMessageStore.addMessage(FJLocalStore._('CONNECT_NETWORK_FAILED'), 'error');
                    }
                })
                .finally(() => {
                    dispatch(mainAction.changeLoadingStatusAction(false));
                });
        };
    },
};
