import classNames from 'classnames';
import styles from './FJCheckBox.module.less';
import InlineSVG from 'react-inlinesvg';
import OkSvg from '../../../icon/ok.svg';

const FJCheckBox = props => {
    const { className, active } = props;
    return (
        <div className={classNames(styles.check_box, className, active && styles.active)}>
            <InlineSVG src={OkSvg} className={styles.check_icon} />
        </div>
    );
};
export default FJCheckBox;
