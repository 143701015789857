import Style from './CouponBrick.module.less';
import FJLocalStore from '../../../store/FJLocalStore';
import { useCallback, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import FJButton from '../../common/FJButton/FJButton';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../../reducer/action/mainAction';
import FJMessageStore from '../../../store/FJMessageStore';
import FJUtil from '../../../util/FJUtil';
import { useAmount } from '../../../hooks/useAmount';

let CouponBrick = (props, ref) => {
    let { countPrice, isAnnually, active, plan = {} } = props;

    let [hasInput, setHasInput] = useState(false);
    let [loading, setLoading] = useState(false);
    let inputRef = useRef(null);

    const dispatch = useDispatch();
    const selectPage = useSelector(state => state.mainReducer.selectPage);
    const dataInfo = useSelector(state => state.mainReducer.dataInfo);
    const discountConf = useSelector(state => state.mainReducer.discountConf);
    const currentCountry = useSelector(state => state.mainReducer.currentCountry);

    // 当前用户所在地区货币符号
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);

    useImperativeHandle(ref, () => {
        return {
            getRealPrice: _realPrice,
        };
    });

    useEffect(() => {
        // 分区定价
        let coupon = '';
        if (active && discountConf.openDiscount) {
            let lists = discountConf.lists;
            if (!lists) return;
            for (const item of lists) {
                if (!item.package) {
                    coupon = item.coupon;
                }
                if (item.package === selectPage) {
                    if (isAnnually) {
                        coupon = item.coupon;
                    }
                }
            }
        } else {
            coupon = FJUtil.getCookie('coupon');
        }
        if (coupon) {
            dispatch(mainAction.changeLoadingStatusAction(true));
            window.FJGlobalariable.modSubscription.network
                .redeemPointCoupon(coupon, plan.packageName, currentCountryMonetaryISO)
                .then(res => {
                    switch (res.code) {
                        case 200:
                            if (res.data.allow_coupon) {
                                inputRef.current.value = coupon;
                                window.FJGlobalariable.modSubscription.state.appliedCoupon = coupon;
                                dispatch(
                                    mainAction.setDataInfo({
                                        appliedCoupon: coupon,
                                        coupon: coupon,
                                        couponObject: res.data.coupon_object,
                                        estimateAmount: res.data,
                                        freeOfCharge: res.data.free_of_charge,
                                    }),
                                );
                            }
                            break;
                        case 214:
                            FJUtil.checkLogin(res.code);
                            // FJMessageStore.addMessage(FJLocalStore._('PAGE_EXPIRED'), 'warning');
                            break;
                        case 335:
                            FJUtil.checkLogin(res.code);
                            break;
                    }
                })
                .catch(err => {
                    console.log(err);
                    dispatch(mainAction.changeLoadingStatusAction(false));
                })
                .finally(() => {
                    window.FJGlobalariable.modSubscription.showLoading = false;
                    dispatch(mainAction.changeLoadingStatusAction(false));
                });
        } else {
            dispatch(
                mainAction.setDataInfo({
                    appliedCoupon: null,
                }),
            );
        }
    }, [discountConf, selectPage, isAnnually, active, dispatch, plan.packageName, currentCountryMonetaryISO]);

    let _onClick = () => {
        setHasInput(!hasInput);
    };
    let _onChange = event => {
        let value = event.target.value;
        if (!/^(|[a-zA-Z0-9_]{0,50})$/.test(value)) {
            event.target.value = value.substr(0, 50);
        }
    };

    let redeemCoupon = callback => {
        let coupon = inputRef.current.value;

        dispatch(mainAction.changeLoadingStatusAction(true));
        setLoading(true);
        window.FJGlobalariable.modSubscription.network
            .redeemPointCoupon(coupon, plan.packageName, currentCountryMonetaryISO)
            .then(res => {
                switch (res.code) {
                    case 200:
                        if (res.data.allow_coupon) {
                            setHasInput(false); // 关闭优惠码输入窗口
                            // 展示预估价格
                            window.FJGlobalariable.modSubscription.state.appliedCoupon = coupon;
                            dispatch(
                                mainAction.setDataInfo({
                                    appliedCoupon: coupon, // 设置应用后的coupon
                                    coupon: coupon,
                                    couponObject: res.data.coupon_object,
                                    estimateAmount: res.data,
                                    freeOfCharge: res.data.free_of_charge,
                                }),
                            );
                        } else {
                            // 不可使用coupon
                            // dataInfo.appliedCoupon = null;
                            dispatch(
                                mainAction.setDataInfo({
                                    appliedCoupon: null,
                                }),
                            );
                            FJMessageStore.addMessage(FJLocalStore._('COM_FJ_SP_COUPON_CONTINUOUS'), 'warning');
                        }
                        break;
                    case 214:
                        FJUtil.checkLogin(res.code);
                        // FJMessageStore.addMessage(FJLocalStore._('PAGE_EXPIRED'), 'warning');
                        break;
                    case 335:
                        FJUtil.checkLogin(res.code);
                        break;
                    default:
                        // dataInfo.appliedCoupon = null;
                        dispatch(
                            mainAction.setDataInfo({
                                appliedCoupon: null,
                            }),
                        );
                        if (res.msg === 'SUB.COM_FJ_SP_COUPON_MATCH_PLAN_ERROR') {
                            let list = res.data.split(',');
                            list = list.map(item => {
                                // 将拆分之后的元素筛除掉''
                                let arr = item.split(' ').filter(splitItem => splitItem.length > 0);
                                arr = arr.map(arrItem => {
                                    // 将字符串前后的空格删除之后去匹配本地化
                                    return FJLocalStore._(arrItem.trim().toUpperCase());
                                });
                                return arr.join('');
                            });
                            let MSG = res.msg.replace('SUB.', '');
                            if (window.text_printf) {
                                FJMessageStore.addMessage(
                                    window.text_printf(FJLocalStore._(MSG), list.join(', ')),
                                    'warning',
                                );
                            }
                        } else if (res.msg === 'SUB.CANNOT_USED_FOR_UPGRADE') {
                            FJMessageStore.addMessage(FJLocalStore._('CANNOT_USED_FOR_UPGRADE'), 'warning');
                        } else if (res.msg === 'SUB.COM_FJ_SP_COUPON_NOT_ENOUGH_CURRENCY') {
                            FJMessageStore.addMessage(FJLocalStore._('the-code-only-applies-to-payments'), 'error');
                        } else {
                            let MSG = res.msg.replace('SUB.', '');
                            if (window.text_) {
                                FJMessageStore.addMessage(window.text_(FJLocalStore._(MSG)), 'warning');
                            }
                        }
                }
            })
            .catch(err => {
                // dataInfo.appliedCoupon = null;
                dispatch(
                    mainAction.setDataInfo({
                        appliedCoupon: null,
                    }),
                );
                FJMessageStore.addMessage(FJLocalStore._('COM_FJ_SP_COUPON_NOT_EXIST'), 'warning');
                console.log(err);
            })
            .finally(() => {
                // this.isRedeeming = false;
                setLoading(false);
                if (typeof callback === 'function') {
                    callback();
                }
                dispatch(mainAction.changeLoadingStatusAction(false));
            });
    };
    let _submit = () => {
        redeemCoupon();
    };

    const _autoPayPrice = useCallback(() => {
        return countPrice ? countPrice.price : 0;
    }, [countPrice]);

    let _realPrice = useCallback(
        shouldToFixed => {
            if (dataInfo.appliedCoupon && dataInfo.couponObject) {
                let amount = _autoPayPrice();
                if (dataInfo.couponObject.amount_off) {
                    amount = amount - dataInfo.couponObject.amount_off;
                    if (shouldToFixed) {
                        amount = amount.toFixed(2);
                    }
                } else if (dataInfo.couponObject.percent_off) {
                    amount = (1 - parseFloat(dataInfo.couponObject.percent_off) / 100) * amount;
                    if (shouldToFixed) {
                        amount = amount.toFixed(2);
                    }
                }

                if (amount < 0) amount = 0;
                return amount;
            } else {
                return _autoPayPrice();
            }
        },
        [dataInfo, _autoPayPrice],
    );

    const payMessage = useMemo(() => {
        return currentCountry === 'US' || currentCountryMonetaryISO === 'EUR'
            ? ''
            : FJLocalStore._('EXPIRED_PLAN_TIP').replace('xxx', `$USD${parseFloat(_realPrice(true))}`);
    }, [_realPrice, currentCountry, currentCountryMonetaryISO]);

    const amount = Math.floor(FJUtil.preciseMultiply([parseFloat(_realPrice(true)), 1]).toFixed(2) * 100);
    useAmount(amount);

    return (
        <>
            <div className={Style.total_billed_box}>
                <div className={Style.billedLeft}>
                    <div className={Style.toolBilledTitle}>{FJLocalStore._('TOTAL_BILLED')}</div>
                    <span className={Style.currentCountryMonetary}>
                        {currentCountryMonetary || '$'}
                        {currentCountryMonetaryISO}
                    </span>
                    <span className={Style.curPrice}>
                        {FJUtil.formatNumberThousandth(
                            FJUtil.preciseMultiply([parseFloat(_realPrice(true)), exchangeRate]).toFixed(2),
                        )}
                    </span>
                    {dataInfo.appliedCoupon !== null && (
                        <span className={Style.oldPrice}>
                            {FJUtil.formatNumberThousandth(
                                FJUtil.preciseMultiply([parseFloat(_autoPayPrice()), exchangeRate]).toFixed(2),
                            )}
                        </span>
                    )}
                </div>
                <div className={Style.coupon_box}>
                    <span className={Style.coupon_entry} onClick={_onClick}>
                        {FJLocalStore._('HAVE_A_COUPON')}
                    </span>
                    <div className={classNames(Style.input_box, { [Style.input_box_show]: hasInput })}>
                        <input
                            className={Style.input}
                            ref={inputRef}
                            onChange={_onChange}
                            placeholder={FJLocalStore._('REDEEM_PLACEHOLDER')}
                        />
                        <FJButton
                            isLoading={loading}
                            className={Style.btn}
                            onClick={_submit}
                            text={FJLocalStore._('REDEEM')}
                        />
                    </div>
                </div>
            </div>
            {dataInfo.appliedCoupon !== null && (
                <span className={Style.tip}>{FJLocalStore._('REDEEM_SUCCESSFUL')}</span>
            )}
            <div className={Style.pay_message}>{payMessage}</div>
        </>
    );
};
export default forwardRef(CouponBrick);
