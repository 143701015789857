import FJConfig from '../config/FJConfig';
import $ from 'jquery';
import FJUtil from '../util/FJUtil';

const ajaxPoster = function (options) {
    let { url, data, success, error, complete, timeout } = options;
    if (!data) data = {};
    // if (window.csrf_token) data[window.csrf_token] = 1;
    try {
        let r = null;
        if (timeout) {
            r = $.ajax(url, {
                type: 'POST',
                data: data,
                timeout: timeout,
                success: success ? success : () => {},
                error: error ? error : () => {},
                complete: complete
                    ? complete
                    : (XMLHttpRequest, status) => {
                          if (status === 'timeout') {
                              //超时,status还有success,error等值的情况
                              error();
                          }
                      },
            });
        } else {
            r = $.ajax(url, {
                type: 'POST',
                data: data,
                success: success ? success : () => {},
                error: error ? error : () => {},
                complete: complete ? complete : () => {},
            });
        }
        return r;
    } catch (e) {
        if (error) {
            error();
        }
    }
};

export default {
    // 检查账号是否存在
    checkAccountExist(data) {
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/user/check-email',
                data: {
                    email: data.email,
                },
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },
    // 获取城市code
    getUserCountryCode(data) {
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: 'https://www.cloudflare.com/cdn-cgi/trace',
                data: {},
                timeout: 3000,
                success: data => {
                    let localCountryCode = data
                        .split('\n')
                        .find(item => item.indexOf('loc=') !== -1)
                        .match(/=(.*)/)[1];
                    resolve(localCountryCode);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    getCurrentCountry() {
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: '',
                data: {},
                timeout: 3000,
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    // 获取用户支付卡号
    getCardInfo() {
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/subscription/get_card_info',
                data: {},
                timeout: 3000,
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    getCustomerSession() {
        let post_data = {};
        if (window.csrf_token) {
            post_data[window.csrf_token] = 1;
        }

        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/payment/create-customer-sessions',
                data: post_data,
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    closeStripePopPanel(teamId, prepaymentId) {
        let post_data = {};
        if (teamId) {
            post_data = { tid: teamId };
        }

        if (prepaymentId) {
            post_data.prepayment_id = prepaymentId;
        }

        if (window.csrf_token) {
            post_data[window.csrf_token] = 1;
        }
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/payment/close-pop-up',
                data: post_data,
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    createSubscription(options = {}, teamId, seats) {
        let post_data = { ...options };
        if (teamId) {
            post_data = { ...post_data, tid: teamId, seats: seats };
        }

        if (window.csrf_token) {
            post_data[window.csrf_token] = 1;
        }
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/payment/create-subscription',
                data: post_data,
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    createOneTimePayment(options = {}, teamId, seats) {
        let post_data = { ...options };
        if (teamId) {
            post_data = { ...post_data, tid: teamId, seats: seats };
        }

        if (window.csrf_token) {
            post_data[window.csrf_token] = 1;
        }
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/payment/create-one-time-payment',
                data: post_data,
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    buyCredits(options = {}) {
        let post_data = { ...options };
        if (window.csrf_token) {
            post_data[window.csrf_token] = 1;
        }
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/payment/buy-credits',
                data: post_data,
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    getPaymentStatus() {
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/payment/get-payment-status',
                data: {},
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    getStripeRealPrice(options, teamId, seats) {
        let post_data = { ...options };
        if (teamId) {
            post_data = { ...post_data, tid: teamId, seats: seats };
        }

        if (window.csrf_token) {
            post_data[window.csrf_token] = 1;
        }

        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/payment/get-stripe-real-price',
                data: post_data,
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    getCreditsInfo() {
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/user/get-credit-info',
                data: {},
                success: data => {
                    resolve(data);
                },
                error: e => {
                    reject(e);
                },
            });
        });
    },

    checkIsLogin() {
        return new Promise((resolve, reject) => {
            ajaxPoster({
                url: FJConfig.requestPath + '/user/checkState',
                data: {},
                success: result => {
                    const { code, data } = result;
                    resolve(data?.result && code === 200);
                },
                error: e => {
                    resolve(false);
                },
            });
        });
    },
};
