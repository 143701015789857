import styles from './FJPaySuccess.module.less';
import FJLocalStore from '../../../store/FJLocalStore';
import InlineSvg from 'react-inlinesvg';
import PaySuccessSvg from '../../../icon/pay-success.svg';
import PaySuccessBgSvg from '../../../icon/pay-success-bg.svg';
import CloseSvg from '../../../icon/mobile-close.svg';
import FJUtil from '../../../util/FJUtil';
import FJMobileFooter from '../FJMobileFooter/FJMobileFooter';
import useRealPayPrice from '../../../hooks/useRealPayPrice';
import usePricePrefix from '../../../hooks/usePricePrefix';

const FJPaySuccess = props => {
    const { closePanel, startNow, exchangeRate } = props;
    const realPrice = useRealPayPrice();
    const pricePrefix = usePricePrefix();
    const price =
        pricePrefix +
        FJUtil.formatNumberThousandth(FJUtil.preciseMultiply([parseFloat(realPrice), exchangeRate]).toFixed(2));
    return (
        <div className={styles.box}>
            <div className={styles.close_btn} onClick={closePanel}>
                <InlineSvg src={CloseSvg} />
            </div>
            <div className={styles.main}>
                <InlineSvg className={styles.background} src={PaySuccessBgSvg} />
                <InlineSvg src={PaySuccessSvg} />
                <div className={styles.title}> {FJLocalStore._('payment-successful')}</div>
                <div className={styles.desc}>{price}</div>
            </div>

            <FJMobileFooter btnClassName={styles.pay_btn} text={FJLocalStore._('StartNow')} onClick={startNow} />
        </div>
    );
};
export default FJPaySuccess;
