import { useDispatch, useSelector } from 'react-redux';
import FJCreditsPurchasePanel from './FJCreditsPurchasePanel';
import { useEffect } from 'react';
import FJMobilePayAction from '../../../reducer/action/FJMobilePayAction';
import FJViewConstant from '../../../constant/FJViewConstant';
import FJUtil from '../../../util/FJUtil';
import mainAction from '../../../reducer/action/mainAction';

const FJCreditsPurchasePanelContainer = () => {
    const dispatch = useDispatch();
    const creditsPayData = useSelector(state => state.mainReducer.creditsPayData);
    const creditsNum = useSelector(state => state.mainReducer.creditsNum);

    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);
    useEffect(() => {
        dispatch(FJMobilePayAction.updateCreditsNumAction());
    }, []);
    const gotoBack = () => {
        dispatch(mainAction.closeSubscribeView());
    };
    const payNow = () => {
        if (!FJUtil.checkIsLogin() && !FJUtil.isLocalhost()) {
            return;
        }
        dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.PAYMENT_METHOD));
    };
    return (
        <FJCreditsPurchasePanel
            creditsPayData={creditsPayData}
            creditsNum={creditsNum}
            gotoBack={gotoBack}
            payNow={payNow}
            exchangeRate={exchangeRate}
        />
    );
};
export default FJCreditsPurchasePanelContainer;
