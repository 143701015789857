import classNames from 'classnames';
import useIsMobile from '../../hooks/useIsMobile';
import Style from './LoadingBrickPopup.module.less';

let LoadingBrickPopup = props => {
    const isMobile = useIsMobile();
    return (
        <div className={classNames(Style.fj_sub_modal_loading, isMobile && Style.mobile)}>
            <div className={Style.mask}>
                <div className={Style.box}>
                    <svg viewBox='0 0 50 50' className={Style.svg_load}>
                        <circle cx='25' cy='25' r={25} fill='none' className={Style.svg_loading} />
                    </svg>
                </div>
            </div>
        </div>
    );
};
export default LoadingBrickPopup;
