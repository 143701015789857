import styles from './FJMobileHeader.module.less';
import arrowSvg from '../../../icon/arrow-header.svg';
import InlineSvg from 'react-inlinesvg';
import { useEffect, useRef, useState } from 'react';
const FJMobileHeader = ({ title, gotoBack }) => {
    const headerRef = useRef(null);
    const [isTransparent, setIsTransparent] = useState(true);
    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.parentNode.onscroll = e => {
                const scrollTop = e.target.scrollTop;
                if (scrollTop < 20) {
                    setIsTransparent(true);
                } else if (isTransparent) {
                    setIsTransparent(false);
                }
            };
        }
    }, []);
    return (
        <div className={styles.header} ref={headerRef} style={{ background: isTransparent ? 'transparent' : '#FFF' }}>
            <div className={styles.left} onClick={gotoBack}>
                <InlineSvg src={arrowSvg} />
            </div>
            <div className={styles.middle}>{title}</div>
            <div className={styles.right} />
        </div>
    );
};
export default FJMobileHeader;
