/* eslint-disable complexity */
import FJActionConstant from '../../constant/FJActionConstant';
import FJViewConstant from '../../constant/FJViewConstant';
import FJUtil from '../../util/FJUtil';
const PURCHASES = [
    {
        base: '200',
        baseUnit: 'Credits',
        present: '',
        presentUnit: '',
        price: '4.99',
        priceUnit: '$',
        packageName: 'ai_point_package_1',
    },
    {
        base: '400',
        baseUnit: 'Credits',
        present: '50',
        presentUnit: 'Credits',
        price: '9.99',
        priceUnit: '$',
        packageName: 'ai_point_package_2',
    },
    {
        base: '600',
        baseUnit: 'Credits',
        present: '100',
        presentUnit: 'Credits',
        price: '14.99',
        priceUnit: '$',
        packageName: 'ai_point_package_3',
    },
    {
        base: '800',
        baseUnit: 'Credits',
        present: '150',
        presentUnit: 'Credits',
        price: '19.99',
        priceUnit: '$',
        packageName: 'ai_point_package_4',
    },
    {
        base: '1,000',
        baseUnit: 'Credits',
        present: '250',
        presentUnit: 'Credits',
        price: '24.99',
        priceUnit: '$',
        packageName: 'ai_point_package_5',
    },
    {
        base: '1,200',
        baseUnit: 'Credits',
        present: '400',
        presentUnit: 'Credits',
        price: '29.99',
        priceUnit: '$',
        packageName: 'ai_point_package_6',
    },
];
let initData = {
    show: false, // 是否打开订阅视图
    method: 'single', // 打开方式 popup single
    planType: 'personal', // 当前页面的套餐类型 personal team
    isAnnually: 'true', // 是否按照每年计费
    step: 1, // 1:选择套餐 | 2: 选择支付方式 | 3：支付成功
    selectPage: 'free', // 哪种套餐
    entry: null, // 旧版本 打开方式
    payMethod: 'stripe', // 支付方式 stripe | paypal
    appliedCoupon: null, // 应用成功的coupon
    loading: false, // 加载状态
    isEditCardInfo: false, // 是否打开修改银行卡面板
    cardParams: null, // 个人中心改卡参数
    hasLoadPaypalSdk: false, // 是否成功加载paypal的sdk
    currentPlan: {
        package: 'free',
        period: null,
        last4: null,
        price: null,
        period_end: null,
        status: null,
        sub_type: '',
    },
    dataInfo: {
        amount: null,
        coupon: null,
        appliedCoupon: null, // 应用成功的coupon
        freeOfCharge: null,
        estimateAmount: null,
        couponObject: {
            amount_off: null,
            percent_off: null,
        },
        transaction_id: null,
        discount: null,
    },
    discountConf: {
        // 折扣配置
        coupon: '',
        rate: 1,
        limit: [],
        lists: [],
        openDiscount: false,
    },
    currentDiscountData: [],
    planData: [
        {
            name: 'FREE_NAME', // 套餐名字，
            package: 'free', // 订阅套餐可能值 free, basic, full
            isPopular: false,
            shrink: false,
            price: {
                monthly: 0,
                annual: 0,
            },
            features: [
                {
                    icon: 'tick',
                    text: 'BASIC_FEATURE_1',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'FREE_FEATURE_2',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'FREE_FEATURE_6',
                    desc: '',
                },
                {
                    icon: 'tick',
                    key: FJViewConstant.AI_QUOTA,
                    text: 'ai-quota',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'FREE_FEATURE_3',
                    desc: 'PLANS_ITEM_FREE_INFO_3',
                },
                {
                    icon: 'tick',
                    text: 'FREE_FEATURE_4',
                    desc: '',
                },
            ],
            buttonText: 'BUTTON_GET_START',
            shortFeatures: [],
            backendPackage: 'free',
            teamFeatures: [
                {
                    icon: 'tick',
                    text: 'BASIC_FEATURE_1',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'FREE_FEATURE_2',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'FREE_FEATURE_6',
                    desc: '',
                },
                {
                    icon: 'tick',
                    key: FJViewConstant.AI_QUOTA,
                    text: 'ai-quota',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'FREE_FEATURE_4',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: '500MB cloud storage',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_1',
                    desc: '',
                },
            ],
        },
        {
            name: 'PLUS_NAME', // 套餐名字，
            package: 'plus', // 订阅套餐可能值 free, base, plus
            isPopular: true,
            shrink: true,
            price: {
                monthly: 19.99,
                annual: 11.99,
            },
            features: [
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_1',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_2',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_12',
                    desc: '',
                },
                {
                    icon: 'tick',
                    key: FJViewConstant.AI_QUOTA,
                    text: 'ai-quota',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_3',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'unlimited',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'COMPARE_TD_5',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_7',
                    desc: 'PLANS_ITEM_PLUS_INFO_7',
                },
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_8',
                    desc: 'PLANS_ITEM_PLUS_INFO_8',
                },
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_9',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_11',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'COMPARE_TD_6',
                    desc: 'COMPARE_TD_6_desc',
                },
            ],
            save: '40%',
            buttonText: 'BUTTON_GET_PLUS',
            shortFeatures: [
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_1',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_2_POPUP',
                    desc: 'PLANS_ITEM_PLUS_INFO_2_POPUP',
                },
                {
                    icon: 'tick',
                    key: FJViewConstant.AI_QUOTA,
                    text: 'ai-quota',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'PLUS_FEATURE_7',
                    desc: 'PLANS_ITEM_PLUS_INFO_7',
                },
                {
                    icon: 'tick',
                    text: 'COMPARE_TD_6',
                    desc: 'COMPARE_TD_6_desc',
                },
            ],
            backendPackage: 'plus',
        },
        {
            name: 'BUSINESS_NAME', // 套餐名字，
            package: 'business', // 订阅套餐可能值 free, base, plus, business
            isPopular: false,
            shrink: true,
            price: {
                monthly: 29.99,
                annual: 19.99,
            },
            features: [
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_1',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_2',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_12',
                    desc: '',
                },
                {
                    icon: 'tick',
                    key: FJViewConstant.AI_QUOTA,
                    text: 'ai-quota',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_3',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'unlimited',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'COMPARE_TD_5',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_7',
                    desc: 'PLANS_ITEM_BUSINESS_INFO_7',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_8',
                    desc: 'PLANS_ITEM_BUSINESS_INFO_8',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_9',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_11',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'COMPARE_TD_6',
                    desc: 'COMPARE_TD_6_desc',
                },
            ],
            save: '33%',
            buttonText: 'BUTTON_GET_BUSINESS',
            shortFeatures: [
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_1',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_2_POPUP',
                    desc: '',
                },
                {
                    icon: 'tick',
                    key: FJViewConstant.AI_QUOTA,
                    text: 'ai-quota',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_7',
                    desc: 'PLANS_ITEM_BUSINESS_INFO_7',
                },
                {
                    icon: 'tick',
                    text: 'COMPARE_TD_6',
                    desc: 'COMPARE_TD_6_desc',
                },
            ],
            backendPackage: 'business',
        },
        {
            name: 'TEAM_NAME', // 套餐名字，
            package: 'team', // 订阅套餐可能值 free, base, plus, business
            isPopular: true,
            shrink: true,
            price: {
                monthly: 29.99,
                annual: 19.99,
            },
            seatPrice: {
                monthly: 19.99,
                annual: 9.99,
            },
            features: [
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_1',
                    desc: 'Team management description',
                },
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_2',
                    desc: 'Full premium access for every team member description',
                },
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_3',
                    desc: 'Centralized billing and account management description',
                },
                {
                    icon: 'tick',
                    key: FJViewConstant.AI_QUOTA,
                    text: 'ai-quota',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_4',
                    desc: 'Priority support description',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_1',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_2_POPUP',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'unlimited',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'COMPARE_TD_5',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_7',
                    desc: 'PLANS_ITEM_BUSINESS_INFO_7',
                },
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_8',
                    desc: 'PLANS_ITEM_BUSINESS_INFO_8',
                },
                {
                    icon: 'tick',
                    text: 'BUSINESS_FEATURE_9',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'Save custom templates',
                    desc: '',
                },
                {
                    icon: 'tick',
                    text: 'COMPARE_TD_6',
                    desc: 'COMPARE_TD_6_desc',
                },
            ],
            save: '33%',
            buttonText: 'create-team',
            shortFeatures: [
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_1',
                    desc: 'Team management description',
                },
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_2',
                    desc: 'Full premium access for every team member description',
                },
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_3',
                    desc: 'Centralized billing and account management description',
                },
                {
                    icon: 'tick',
                    text: 'TEAM_FEATURE_4',
                    desc: 'Priority support description',
                },
            ],
            backendPackage: 'team_business',
        },
    ],
    additionalSeatCount: null, // 邀请团队成员时额外的席位数量
    seatCount: 3, // 准备支付的席位数量，邀请团队成员时使用 additionalSeatCount
    calculatorSeatCount: 3, // 价格计算器默认的席位数量
    teamSubscription: null, // 团队订阅信息，有团队时跟 currentPlan 结构类似
    minSeatCount: 3, // 最小席位数
    maxSeatCount: FJUtil.isTest() ? 20 : 500, // 最大席位数

    panelName: 'Subscription', // 默认打开订阅面板，可以切换到积分面板 Subscription Credits
    showCreditRuleTable: true, // 默认打开积分对照表
    displayPanelButton: true, // 是否显示切换订阅、积分两个按钮
    openCreditsPayPanel: false, // 是否打开积分的购买支付面板

    creditsPayData: {
        index: 0,
        info: PURCHASES[0],
    }, // 支付时记录的当前选择的积分套餐

    // 积分套餐
    purchase: PURCHASES,

    showCreditsEndPanel: false, // 积分套餐支付成功后显示成功页面
    showQuestionnaire: true,
    currentCountry: 'US',
    currentCountryMonetary: '$', // 当前用户所在地区的货币
    currentCountryMonetaryISO: 'USD', // 当前用户所在地区的货币ISO码
    exchangeRate: 1, // 当前用户所在地区货币与美元之间的汇率

    loadStripeUIStatus: FJViewConstant.START,
    amount: 0,
    creditsNum: 0,
    mobilePayStep: FJViewConstant.NONE,
};
const FJMainReducer = (state = initData, action) => {
    switch (action.type) {
        case FJActionConstant.SET_AMOUNT: {
            return {
                ...state,
                amount: action.data,
            };
        }
        case FJActionConstant.CHANGE_LOAD_STRIPE_UI_STATUS: {
            return {
                ...state,
                loadStripeUIStatus: action.data,
            };
        }
        case FJActionConstant.SHOW_SUBSCRIBE_VIEW: {
            const extraChange = {};
            if (action.planType === 'team' && action.method === 'popup') {
                // 选中 team 套餐
                extraChange.selectPage = 'team';
                // 直接跳转到支付页面
                extraChange.step = 2;
            }

            return {
                ...state,
                show: true,
                isAnnually: true, // 每次打开周期默认都是年
                entry: action.entry,
                method: action.method,
                planType: action.planType,
                additionalSeatCount: action.additionalSeatCount,
                calculatorSeatCount: action.calculatorSeatCount,
                panelName: action.panelName,
                showCreditRuleTable: action.showCreditRuleTable,
                showQuestionnaire: action.showQuestionnaire,
                ...extraChange,
            };
        }
        case FJActionConstant.SET_SEAT_COUNT:
            return {
                ...state,
                calculatorSeatCount: action.value,
            };
        case FJActionConstant.CLOSE_SUBSCRIBE_VIEW:
            return {
                ...state,
                show: false,
            };
        case FJActionConstant.CHANGE_PAY_METHOD:
            return {
                ...state,
                payMethod: action.method,
            };
        case FJActionConstant.CHANGE_CURRENT_STEP:
            return {
                ...state,
                step: action.step,
            };
        case FJActionConstant.CHANGE_SELECT_PAGE:
            return {
                ...state,
                selectPage: action.selectPage,
            };
        case FJActionConstant.CHANGE_LOADING_STATUS:
            return {
                ...state,
                loading: action.status,
            };
        case FJActionConstant.OPEN_CARD_INFO_EDIT_POPUP:
            return {
                ...state,
                isEditCardInfo: action.status,
                cardParams: action.cardParams,
            };
        case FJActionConstant.CLOSE_CARD_INFO_EDIT_POPUP:
            return {
                ...state,
                isEditCardInfo: action.status,
            };
        case FJActionConstant.SET_CURRENT_PLAN:
            return {
                ...state,
                currentPlan: { ...state.currentPlan, ...action.obj },
            };
        case FJActionConstant.SET_DATA_INFO:
            return {
                ...state,
                dataInfo: { ...state.dataInfo, ...action.obj },
            };
        case FJActionConstant.SET_DISCOUNT_CONF:
            return {
                ...state,
                discountConf: { ...state.discountConf, ...action.obj },
            };
        case FJActionConstant.SET_CURRENT_DISCOUNT_DATA:
            return {
                ...state,
                currentDiscountData: [...action.obj],
            };
        case FJActionConstant.SET_PAYPAL_SDK_STATUS:
            return {
                ...state,
                hasLoadPaypalSdk: action.value,
            };
        case FJActionConstant.UPDATE_SEAT_COUNT: {
            const seatCount = action.count || 3;
            return {
                ...state,
                seatCount,
            };
        }
        case FJActionConstant.SET_TEAM_SUBSCRIPTION: {
            const teamSubscription = { ...action.teamSubscription };
            const extraChange = {};
            if (state.planType === 'team') {
                const { members = 0, seats = 0 } = teamSubscription;
                // 最小席位数等于团队人数或席位数
                extraChange.minSeatCount = Math.max(members, seats, 3);
                // 容错，如果最大席位数小于最小席位数，那么最大席位数等于最小席位数
                if (state.maxSeatCount < extraChange.minSeatCount) {
                    extraChange.maxSeatCount = extraChange.minSeatCount;
                }
                // 当前待支付席位数等于最小席位数
                // extraChange.seatCount = extraChange.minSeatCount;

                // 如果是购买席位，那么选中的周期为当前套餐的周期
                if (state.additionalSeatCount) {
                    extraChange.isAnnually = teamSubscription.period === 'annual';
                }
            }
            return {
                ...state,
                teamSubscription: teamSubscription,
                ...extraChange,
            };
        }
        case FJActionConstant.UPDATE_IS_ANNUALLY:
            return {
                ...state,
                isAnnually: action.isAnnually,
            };
        case FJActionConstant.CHANGE_TOP_PANEL:
            return {
                ...state,
                panelName: action.value,
            };
        case FJActionConstant.CHANGE_CHECKED_CREDITS:
            return {
                ...state,
                creditsPayData: { ...state.creditsPayData, ...action.obj },
            };
        case FJActionConstant.OPEN_OR_CLOSE_CREDITS_PAY_PANEL:
            return {
                ...state,
                openCreditsPayPanel: action.value,
            };
        case FJActionConstant.SHOW_OR_HIDDEN_PANEL_BUTTON:
            return {
                ...state,
                displayPanelButton: action.value,
            };
        case FJActionConstant.CHANGE_BUY_CREDITS_SUCCESS_PAGE:
            return {
                ...state,
                showCreditsEndPanel: action.value,
            };
        case FJActionConstant.SET_CURRENT_COUNTRY:
            return {
                ...state,
                currentCountry: action.value,
            };
        case FJActionConstant.SET_CURRENT_COUNTRY_MONETARY:
            return {
                ...state,
                currentCountryMonetary: action.value,
            };
        case FJActionConstant.SET_CURRENT_COUNTRY_MONETARY_ISO:
            return {
                ...state,
                currentCountryMonetaryISO: action.value,
            };
        case FJActionConstant.SET_EXCHANGE_RATE:
            return {
                ...state,
                exchangeRate: action.value,
            };
        case FJActionConstant.SET_MOBILE_PAY_STEP:
            return {
                ...state,
                mobilePayStep: action.data,
            };
        case FJActionConstant.SET_CREDITS_NUM: {
            return {
                ...state,
                creditsNum: action.data,
            };
        }
        default:
            return state;
    }
};
export default FJMainReducer;
