import { useEffect, useState } from 'react';
import FJUtil from '../util/FJUtil';
// 判断当前是否移动端
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(FJUtil.isMobile());
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(FJUtil.isMobile());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};
export default useIsMobile;
